import React, {
  FunctionComponent,
  createContext,
  useContext,
  useEffect,
} from "react";
import { ShoppingCart, ShoppingCartNull } from "../dto/ShoppingCart";
import AddShoppingCartOutlinedIcon from "@material-ui/icons/AddShoppingCartOutlined";
import RemoveShoppingCartOutlinedIcon from "@material-ui/icons/RemoveShoppingCartOutlined";
import { Button } from "@material-ui/core";
import { GetFetchContext, Scope } from "./fetch.context";
import { useSnackbar } from "notistack";
import { GetUserContext } from "./user.context";
import { appConfig } from "../constants";
import { GetBusyContext } from "./busy.context";

export type ShoppingContextType = {
  shoppingCart: ShoppingCart;

  buttonFor: (src: string, title: string) => JSX.Element;
  addToShoppingCart: (item: string, title: string) => void;
  removeFromShoppingCart: (item: string) => void;
  clearShoppingCart: () => Promise<boolean>;
  isInShoppingCart: (item: string) => boolean;
  sendShoppingCart: () => Promise<boolean>;
  readShoppingCart: () => Promise<boolean>;
};

const ShoppingContext = createContext<ShoppingContextType>({
  shoppingCart: ShoppingCartNull,
  buttonFor: (src: string, title: string) => {
    throw new Error();
  },
  addToShoppingCart: (item: string, title: string) => {
    throw new Error();
  },
  removeFromShoppingCart: (item: string) => {
    throw new Error();
  },
  clearShoppingCart: () => {
    throw new Error();
  },
  isInShoppingCart: (item: string) => {
    throw new Error();
  },
  sendShoppingCart: () => {
    throw new Error();
  },
  readShoppingCart: () => {
    throw new Error();
  },
});

type ShoppingProviderProps = {};

export const ShoppingProvider: FunctionComponent<ShoppingProviderProps> = (
  props
) => {
  const busyContext = GetBusyContext();
  const fetchContext = GetFetchContext();
  const snackbar = useSnackbar();
  const userContext = GetUserContext();
  const [shoppingCart, setShoppingCart] = React.useState<ShoppingCart>(
    ShoppingCartNull
  );

  const getHostName = (): string => {
    if (
      window &&
      "location" in window &&
      "protocol" in window.location &&
      "pathname" in window.location &&
      "host" in window.location
    ) {
      return window.location.protocol + "//" + window.location.host;
    }
    return "";
  };

  useEffect(() => {
    shoppingCart.basePath = getHostName();
  }, [shoppingCart]);

  useEffect(() => {
    if (!userContext.loggedIn) return;
    readShoppingCart().catch((err) =>
      snackbar.enqueueSnackbar(err.message, { variant: "error" })
    );
    // eslint-disable-next-line
  }, [userContext.loggedIn]);

  const buttonFor = (src: string, title: string): JSX.Element => {
    if (isInShoppingCart(src)) {
      return (
        <Button size="small" onClick={() => removeFromShoppingCart(src)}>
          <RemoveShoppingCartOutlinedIcon />
        </Button>
      );
    } else {
      return (
        <Button size="small" onClick={() => addToShoppingCart(src, title)}>
          <AddShoppingCartOutlinedIcon />
        </Button>
      );
    }
  };

  const readShoppingCart = (): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      const url = appConfig.ApiBaseUri + "/shop";
      busyContext.push();
      fetchContext
        .get(url, Scope.User)
        .then((response) => {
          if (response.status === 204) {
            // No-content
            setShoppingCart(ShoppingCartNull);
            resolve(false);
          }
          return response.json();
        })
        .then((newCart: ShoppingCart) => {
          newCart.items.sort((item1, item2) =>
            item1.title > item2.title ? 1 : -1
          );
          setShoppingCart(newCart);
          resolve(true);
        })
        .catch((err) => reject(err))
        .finally(() => busyContext.pop());
    });
  };

  const addToShoppingCart = (itemToAdd: string, title: string) => {
    const body = {
      shoppingCart: shoppingCart,
      item: {
        title: title,
        item: itemToAdd,
      },
    };
    const url = appConfig.ApiBaseUri + "/shop";
    busyContext.push();
    fetchContext
      .patch(url, Scope.User, body)
      .then((response) => response.json())
      .then((data: ShoppingCart) => {
        data.items.sort((item1, item2) => (item1.title > item2.title ? 1 : -1));
        setShoppingCart(data);
      })
      .catch((err) =>
        snackbar.enqueueSnackbar(err.message, { variant: "error" })
      )
      .finally(() => busyContext.pop());
  };

  const removeFromShoppingCart = (itemToDelete: string) => {
    const body = {
      shoppingCart: shoppingCart,
      item: {
        item: itemToDelete,
      },
    };
    const url = appConfig.ApiBaseUri + "/shop";
    busyContext.push();
    fetchContext
      .delete(url, Scope.User, body)
      .then((response) => response.json())
      .then((data: ShoppingCart) => {
        data.items.sort((item1, item2) => (item1.title > item2.title ? 1 : -1));
        setShoppingCart(data);
      })
      .catch((err) =>
        snackbar.enqueueSnackbar(err.message, { variant: "error" })
      )
      .finally(() => busyContext.pop());
  };

  const clearShoppingCart = (): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      const body = {
        shoppingCart: shoppingCart,
      };
      const url = appConfig.ApiBaseUri + "/shop";
      fetchContext
        .delete(url, Scope.User, body)
        .then((_) => {
          readShoppingCart()
            .then(() => resolve(true))
            .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  };

  const sendShoppingCart = (): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      const url = appConfig.ApiBaseUri + "/shop";
      fetchContext
        .post(url, Scope.User, shoppingCart)
        .then((_) => {
          readShoppingCart()
            .then(() => resolve(true))
            .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  };

  const isInShoppingCart = (itemToCheck: string) => {
    return (
      shoppingCart.items.findIndex((item) => item.item === itemToCheck) > -1
    );
  };

  const activeValue: ShoppingContextType = {
    shoppingCart: shoppingCart,
    buttonFor: buttonFor,
    addToShoppingCart: addToShoppingCart,
    removeFromShoppingCart: removeFromShoppingCart,
    clearShoppingCart: clearShoppingCart,
    isInShoppingCart: isInShoppingCart,
    sendShoppingCart: sendShoppingCart,
    readShoppingCart: readShoppingCart,
  };

  return (
    <ShoppingContext.Provider value={activeValue}>
      {props.children}
    </ShoppingContext.Provider>
  );
};

export const GetShoppingContext = (): ShoppingContextType =>
  useContext(ShoppingContext);
