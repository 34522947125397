import { appConfig } from './constants';
// auth-service.jsx
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { Configuration } from 'msal';

export type UserContextType = {
  hostName?: string,
  token?: string,

  loggedIn: boolean
}

// Msal Configurations
const config: Configuration = {
  auth: {
    authority: appConfig.AuthorityUri,
    clientId: appConfig.AppClientId,
    validateAuthority: false,
    redirectUri: appConfig.RedirectUri,
    postLogoutRedirectUri: appConfig.PostLogoutUri
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};

// Authentication Parameters
const authenticationParameters = {
  scopes: [
    appConfig.ApiScope
  ]
}

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + '/refreshtoken.html'
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)
