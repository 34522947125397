import React, { createContext, FunctionComponent, useContext } from "react";
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { globalHistory } from "@reach/router"
import { appConfig } from "../constants";

const reactPlugin = new ReactPlugin();

if (appConfig.AppInsightsKey) {
    const ai = new ApplicationInsights({
        config: {
            instrumentationKey: appConfig.AppInsightsKey,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: globalHistory }
            }
        }
    });
    ai.loadAppInsights();
}

type AppInsightsContextType = {
    reactPlugin?: ReactPlugin;
    trackEvent: (eventName: string, eventValue: any) => void;
}

const defaultValue: AppInsightsContextType = {
    trackEvent: (eventName: string, eventValue: any) => { }
}

const AppInsightsContext = createContext<AppInsightsContextType>(defaultValue);

type AppInsightsContextProps = {
}

const AppInsightsContextProvider: FunctionComponent<AppInsightsContextProps> = (props) => {
    return (
        <AppInsightsContext.Provider value={defaultValue}>
            {props.children}
        </AppInsightsContext.Provider>
    );
};

export { AppInsightsContextProvider };


export const GetAppInsightsContext = (): AppInsightsContextType => {
    return useContext(AppInsightsContext);
};