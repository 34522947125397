import React, { createContext, FunctionComponent } from "react";
import CookieConsent from "react-cookie-consent";

export type CookieConsentContextType = {
}

export const CookieConsentContext = createContext<CookieConsentContextType>({
});

export type CookieConsentProviderProps = {
}

export const CookieConsentProvider: FunctionComponent<CookieConsentProviderProps> = (props) => {
    const value = {};
    return (
        <CookieConsentContext.Provider value={value}>
            {props.children}
            <CookieConsent style={{ backgroundColor: '#3f51b5', color: '#fafafa'}} buttonStyle={{ color: '#3f51b5', backgroundColor: '#fafafa'}} overlay={true} buttonText="OK" cookieName="waardestellingCookieConsent">
                Waardestelling.com gebruikt cookies om de functionaliteit van de site te verbeteren. Verlaat de site indien u hier geen toestemming voor geeft.
            </CookieConsent>
        </CookieConsentContext.Provider>
    );
}
