import React, { FunctionComponent } from "react"
import { FacetResultsList } from "./facetResultsList";
import { GetSearchContext } from "../contexts/search.context";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export type MainNavProps = {
}

export const MainNav: FunctionComponent<MainNavProps> = () => {
    const classes = useStyles();
    const searchContext = GetSearchContext();

    if (!searchContext.searchResult) return null;

    return (
        <>
            {searchContext.searchResult['@search.facets'].metadata_content_type.length > 1 ? <FacetResultsList title="Bron" facets={searchContext.searchResult['@search.facets'].metadata_content_type} collapsable={false} /> : null }
            <FacetResultsList title="Auteurs" facets={searchContext.searchResult['@search.facets'].auteurs} collapsable={true} />
            <div className={classes.divider} />
            <FacetResultsList title="Plaats" facets={searchContext.searchResult['@search.facets']["pdfmetadata/plaats"]} collapsable={true} />
            <FacetResultsList title="Jaar" facets={searchContext.searchResult['@search.facets']["pdfmetadata/jaar"]} collapsable={true} />
            <FacetResultsList title="Nr RGD" facets={searchContext.searchResult['@search.facets']["pdfmetadata/nrrgd"]} collapsable={true} />
            <FacetResultsList title="Nr DVD" facets={searchContext.searchResult['@search.facets']["pdfmetadata/nrdvd"]} collapsable={true} />
            <FacetResultsList title="Bestand" facets={searchContext.searchResult['@search.facets']["pdfmetadata/parent"]} collapsable={true} />
            <div className={classes.divider} />
            <FacetResultsList title="Thema" facets={searchContext.searchResult['@search.facets']["kcwmetadata/themas"]} collapsable={true} />
            <FacetResultsList title="Locatienummer" facets={searchContext.searchResult['@search.facets']["kcwmetadata/locatienummer"]} collapsable={true} />
            <FacetResultsList title="Locatiesoort" facets={searchContext.searchResult['@search.facets']["kcwmetadata/locatiesoort"]} collapsable={true} />
            <FacetResultsList title="Literatuurcode" facets={searchContext.searchResult['@search.facets']["kcwmetadata/literatuurcode"]} collapsable={true} />
            <FacetResultsList title="Techniek" facets={searchContext.searchResult['@search.facets']["kcwmetadata/oorspronkelijketechniek"]} collapsable={true} />
        </>);
}
