import React, { FunctionComponent, useEffect } from "react";
import { ADUserInfo } from "../dto/ADUserInfo";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, ButtonGroup, Button } from "@material-ui/core";
import { GetFetchContext, Scope } from "../contexts/fetch.context";
import { appConfig } from "../constants";
import { GetUserContext } from "../contexts/user.context";
import { GetBusyContext } from "../contexts/busy.context";

type UsersProps = {}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
}));

export const Users: FunctionComponent<UsersProps> = (props) => {
  const classes = useStyles();
  const [users, setUsers] = React.useState<Array<ADUserInfo>>([]);
  const busyContext = GetBusyContext();
  const fetchContext = GetFetchContext();
  const userContext = GetUserContext();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (!userContext.apiToken) return;
    loadUserData();
    // eslint-disable-next-line
  }, [userContext.apiToken, props]);

  const loadUserData = () => {
    const url: string = appConfig.ApiBaseUri + '/users';
    busyContext.push();
    fetchContext.get(url, Scope.Admin)
      .then(response => response.json())
      .then((data: Array<ADUserInfo>) => {
        data.sort((item1, item2) => item1.displayName > item2.displayName ? 1 : -1);
        setUsers(data);
      })
      .catch(err => snackbar.enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => busyContext.pop());
  }

  const setRole = (id: string, role: string) => {
    const url: string = appConfig.ApiBaseUri + '/users';

    busyContext.push();
    fetchContext.post(url, Scope.Admin, {
      id: id,
      extension_f5082336026d411daf7f0cac4b100511_Role: role
    }).then(_ => loadUserData())
      .catch(err => snackbar.enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => busyContext.pop());
  }

  return (
    <div className={classes.root}>
      <Typography variant="h1">Gebruikers</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Naam</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Rol</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => (
              <TableRow key={index}>
                <TableCell>{user.displayName}</TableCell>
                <TableCell>{user.identities.filter(identity => identity.signInType === 'emailAddress')[0]?.issuerAssignedId}</TableCell>
                <TableCell>
                  <ButtonGroup>
                    <Button
                      onClick={() => setRole(user.id, 'All')}
                      color={!user.extension_f5082336026d411daf7f0cac4b100511_Role || user.extension_f5082336026d411daf7f0cac4b100511_Role === 'All' ? 'secondary' : 'primary'}
                      disabled={!user.extension_f5082336026d411daf7f0cac4b100511_Role || user.extension_f5082336026d411daf7f0cac4b100511_Role === 'All'}>
                      Alles
                    </Button>
                    <Button
                      onClick={() => setRole(user.id, 'Kcw')}
                      color={user.extension_f5082336026d411daf7f0cac4b100511_Role === 'Kcw' ? 'secondary' : 'primary'}
                      disabled={user.extension_f5082336026d411daf7f0cac4b100511_Role === 'Kcw'}>
                      KCW
                    </Button>
                    <Button
                      onClick={() => setRole(user.id, 'Pdf')}
                      color={user.extension_f5082336026d411daf7f0cac4b100511_Role === 'Pdf' ? 'secondary' : 'primary'}
                      disabled={user.extension_f5082336026d411daf7f0cac4b100511_Role === 'Pdf'}>
                      PDF
                    </Button>
                    <Button
                      onClick={() => setRole(user.id, 'SuperUser')}
                      color={user.extension_f5082336026d411daf7f0cac4b100511_Role === 'Super user' ? 'secondary' : 'primary'}
                      disabled={user.extension_f5082336026d411daf7f0cac4b100511_Role === 'SuperUser'}>
                      Super user
                    </Button>
                    <Button
                      onClick={() => setRole(user.id, 'Admin')}
                      color={user.extension_f5082336026d411daf7f0cac4b100511_Role === 'Admin' ? 'secondary' : 'primary'}
                      disabled={user.extension_f5082336026d411daf7f0cac4b100511_Role === 'Admin'}>
                      Administrator
                    </Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}