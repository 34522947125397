import React, { FunctionComponent, useContext, createContext, useEffect } from "react"
import { Navbar } from '../navigation/navbar';
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { GetUserContext } from "./user.context";
import { appConfig } from "../constants";
import { GetFetchContext, Scope } from "./fetch.context";
import { LoggedInDto } from "../dto/LoggedInDto";
import { useSnackbar } from "notistack";
import { GetBusyContext } from "./busy.context";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerHeader: {
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar
    },
    content: {
      flexGrow: 1,
      margin: 0
    }
  }),
);

type AppFrameworkProps = {
}

type AppFrameworkContextType = {
}

const AppFramework = createContext<AppFrameworkContextType>({
});

export const AppFrameworkProvider: FunctionComponent<AppFrameworkProps> = (props) => {
  const classes = useStyles();
  const busyContext = GetBusyContext();
  const userContext = GetUserContext();
  const fetchContext = GetFetchContext();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (userContext.apiToken && userContext.authenticationState === 'Authenticated' && !userContext.loggedIn) {
      const url = appConfig.ApiBaseUri + '/loggedin';

      busyContext.push();
      fetchContext.post(url, Scope.User)
        .then(response => response.json())
        .then((newUser: LoggedInDto) => userContext.setLoggedIn(newUser))
        .catch(err => snackbar.enqueueSnackbar(err.message, { variant: 'error' }))
        .finally(() => busyContext.pop());
    }
    // eslint-disable-next-line
  }, [userContext.loggedIn, userContext.apiToken, userContext.authenticationState]);

  const activeProps: AppFrameworkContextType = {
  }

  return (
    <AppFramework.Provider value={activeProps}>
      <Navbar />
      <main className={classes.content}>
        <div className={classes.drawerHeader} />
        {props.children}
      </main>
    </AppFramework.Provider >
  )
}

export const GetAppFrameworkContext = (): AppFrameworkContextType => {
  return useContext(AppFramework);
};