export const ROOT_Chapter = '2c9d0ea2-a39c-441d-9854-0f271dbf6918';
export const ROOT_Soestdijk = '6b06204e-2612-43fe-b64c-644568cd0eaf';
export const Soestdijk = 'ce98d5c4-afdb-4bbc-8421-d3f5dde8faa5';
export const HOMEPAGE = '956e25f5-d2c0-4ec3-87c9-cff802071535';

export const LABEL_Home = 'Home';
export const LABEL_Search = 'Zoeken...';
export const LABEL_EvenGeduld = 'Even geduld aub...';

export enum TopicTypeEnum {
  Unknown = 0,
  Advies = 1,
  Archiefstuk = 2,
  Interieur = 3,
  Introductie = 4,
  Locatie = 5,
  LocatieTekening = 6,
  Waardestelling = 7,
  Locatiesoort = 8,
  ResourceFolder = 9,
  Thema = 10,
  Beschouwingsperiode = 11,
  Expertisegebied = 12,
  OorspronkelijkeTechniek = 13,
  ObservatieType = 14,
  Instantie = 15,
  Interpretatie = 16,
  Literatuur = 17,
  Observatie = 18,
  Onderzoeksvraag = 19,
  Ontleding = 20,
  Persoon = 21,
  Begrip = 22
}

export function TopicTypeEnumToString(enumValue: TopicTypeEnum | undefined): string {
  if (!enumValue) return "undefined";
  return TopicTypeEnum[enumValue];
}

export const OriginFromContentType = (contentType: string): string => {
  if (contentType === 'text/html; charset=UTF-8') return 'KCW';
  if (contentType === 'application/pdf') return 'PDF';
  contentType = contentType.trim() !== '' ? contentType.trim() : '(onbekend)';
  return contentType;
}

type AppConfig = {
  ApiBaseUri: string;
  ApiScope: string;
  AppClientId: string;
  AppInsightsKey: string;
  AuthorityUri: string;
  BlobContainerUri: string;
  CreateTopicsQueue: string;
  CreateLowResImageQueue: string;
  CreatePdfPreviewsQueue: string;
  CustomAttributeRole: string;
  ExtractImagesFromPdfQueue: string;
  HighResImagesLocation: string;
  HighResPreviewsLocation: string;
  ImagesLocation: string;
  NewPdfQueue: string;
  PdfIndexerName: string;
  PdfPagesLocation: string;
  PdfsLocation: string;
  PostLogoutUri: string;
  PreviewsLocation: string;
  PublicUri: string;
  RedirectUri: string;
  RedoPdfQueue: string;
  ResetPasswordUri: string;
  ResourcesContainerUri: string;
  ShoppingCartQueue: string;
  TopicContainerUri: string;
  UpdateDocumentMetadataQueue: string;
  UploadAccountUri: string;
  UploadContainer: string;
}

export const appConfig: AppConfig = {
  ApiBaseUri: process.env.REACT_APP_API_BASE ?? '',
  ApiScope: process.env.REACT_APP_SCOPE ?? '',
  AppClientId: process.env.REACT_APP_CLIENTID ?? '',
  AppInsightsKey: process.env.REACT_APP_APPINSIGHTS_KEY ?? '',
  AuthorityUri: process.env.REACT_APP_AUTHORITY ?? '',
  BlobContainerUri: process.env.REACT_APP_BLOBCONTAINERPREFIX ?? '',
  CreateTopicsQueue: process.env.REACT_APP_QUEUE_CREATETOPICS ?? '',
  CreateLowResImageQueue: process.env.REACT_APP_QUEUE_CREATELOWRESIMAGE ?? '',
  CreatePdfPreviewsQueue: process.env.REACT_APP_QUEUE_CREATEPDFPREVIEWS ?? '',
  CustomAttributeRole: process.env.REACT_APP_EXTENSION_ROLE ?? '',
  ExtractImagesFromPdfQueue: process.env.REACT_APP_QUEUE_EXTRACTIMAGESFROMPDF ?? '',
  HighResImagesLocation: process.env.REACT_APP_HIGHRESIMAGES ?? '',
  HighResPreviewsLocation: process.env.REACT_APP_HIGHRESPREVIEWS ?? '',
  ImagesLocation: process.env.REACT_APP_IMAGES ?? '',
  NewPdfQueue: process.env.REACT_APP_QUEUE_NEWPDF ?? '',
  PdfIndexerName: process.env.REACT_APP_PDFINDEXER ?? '',
  PdfPagesLocation: process.env.REACT_APP_PDFPAGES ?? '',
  PdfsLocation: process.env.REACT_APP_PDFS ?? '',
  PreviewsLocation: process.env.REACT_APP_PREVIEWS ?? '',
  PostLogoutUri: process.env.REACT_APP_POSTLOGOUTREDIRECTURI ?? '',
  PublicUri: process.env.PUBLIC_URL ?? '',
  RedirectUri: process.env.REACT_APP_REDIRECTURI ?? '',
  RedoPdfQueue: process.env.REACT_APP_QUEUE_REDOPDF ?? '',
  ResetPasswordUri: process.env.REACT_APP_RESETPASSWORD ?? '',
  ResourcesContainerUri: process.env.REACT_APP_RESOURCESCONTAINERPREFIX ?? '',
  ShoppingCartQueue: process.env.REACT_APP_QUEUE_SHOPPINGCART ?? '',
  TopicContainerUri: process.env.REACT_APP_TOPICSCONTAINERPREFIX ?? '',
  UpdateDocumentMetadataQueue: process.env.REACT_APP_QUEUE_UPDATEDOCUMENTMETADATAQUEUE ?? '',
  UploadAccountUri: process.env.REACT_APP_UPLOADACCOUNT ?? '',
  UploadContainer: process.env.REACT_APP_UPLOADCONTAINER ?? '',
}

export const apiScope: string = process.env.REACT_APP_SCOPE ?? '';
