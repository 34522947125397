import React, { FunctionComponent, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Grid, Typography, Button, makeStyles, Theme, createStyles, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField } from "@material-ui/core";
import { GetUserContext, AccessRights } from "../contexts/user.context";
import { GetBusyContext } from "../contexts/busy.context";
import { useSnackbar } from "notistack";
import { QueueRetryResponse } from '../dto/queueRetryResponse';
import { GetFetchContext, Scope } from "../contexts/fetch.context";
import { appConfig } from "../constants";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
    },
    formitem: {
      margin: theme.spacing(2)
    },
    field: {
      width: '100%',
    }
  })
);

type RetryQueueProps = {

}

type Inputs = {
  queuename: string;
  aantal: number;
}

type QueueInfo = {
  queued: number;
  canretry: boolean;
  poisoned: number;
  label: string;
  name: string;
}

export const RetryQueueForm: FunctionComponent<RetryQueueProps> = (props) => {
  const classes = useStyles();
  const { register, handleSubmit, reset } = useForm<Inputs>();
  const fetchContext = GetFetchContext();
  const userContext = GetUserContext();
  const busyContext = GetBusyContext();
  const snackbar = useSnackbar();
  const [message, setMessage] = useState<string>('');
  const [queue, setQueue] = useState<string>('');
  const [queueInfo] = useState<Array<QueueInfo>>([
    { queued: 0, poisoned: 0, canretry: true, label: 'Genereren topics', name: appConfig.CreateTopicsQueue },
    { queued: 0, poisoned: 0, canretry: true, label: 'Genereren lage resolutie afbeeldingen', name: appConfig.CreateLowResImageQueue },
    { queued: 0, poisoned: 0, canretry: true, label: 'Genereren pdf preview afbeeldingen', name: appConfig.CreatePdfPreviewsQueue },
    { queued: 0, poisoned: 0, canretry: true, label: 'Afbeeldingen uit pdf halen', name: appConfig.ExtractImagesFromPdfQueue },
    { queued: 0, poisoned: 0, canretry: true, label: 'PDFs opnieuw', name: appConfig.RedoPdfQueue },
    { queued: 0, poisoned: 0, canretry: true, label: 'Nieuwe PDFs verwerken', name: appConfig.NewPdfQueue },
    { queued: 0, poisoned: 0, canretry: true, label: 'PDF metadata bijwerken', name: appConfig.UpdateDocumentMetadataQueue },
    { queued: 0, poisoned: 0, canretry: true, label: 'Winkelwagentje', name: appConfig.ShoppingCartQueue }
  ]);

  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    setIsAdmin(userContext.hasAccessRights(AccessRights.Admin));
  }, [userContext, setIsAdmin]);


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    if (!userContext.apiToken) return;
    const promises = new Array<Promise<any>>();
    busyContext.push();
    queueInfo.forEach(queueItem => {
      const url: string = appConfig.ApiBaseUri + '/retry/' + queueItem.name;
      promises.push(
        fetchContext.get(url, Scope.Admin)
          .then((response) => response.json())
          .then((retryResponse: QueueRetryResponse) => {
            queueItem.queued = Math.max(retryResponse.queued, 0);
            queueItem.poisoned = Math.max(retryResponse.poisoned, 0);
          })
          .catch((err: Error) => snackbar.enqueueSnackbar(err.message, { variant: 'error' }))
      );
      Promise.all(promises)
        .then(() => busyContext.pop());
    });
    // eslint-disable-next-line
  }, [userContext.apiToken, props]);

  const onSubmit = (data: Inputs) => {
    if (!data?.queuename) return;

    let url: string = appConfig.ApiBaseUri + '/retry/' + data.queuename;
    if (data.aantal) url = url + '/' + data.aantal;
    busyContext.push();
    fetchContext.post(url, Scope.Admin, {})
      .then((response) => response.text())
      .then(msg => {
        setMessage(msg);
        reset();
      })
      .catch((err: Error) => snackbar.enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => busyContext.pop());
  }

  return <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
    <Grid container direction="column">
      <Grid item className={classes.formitem}>
        <Typography variant='h1'>
          Retry queue
                </Typography>
      </Grid>
      <Grid item className={classes.formitem}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Queue</FormLabel>
          <RadioGroup aria-label="queuename" name="queuename" value={queue} onChange={handleChange}>
            {queueInfo.map((qi, index) => <FormControlLabel key={index} disabled={!isAdmin || qi.poisoned <= 0 || !qi.canretry} value={qi.name} control={<Radio inputRef={register} />}
              label={
                <>
                  <Typography variant="subtitle1">{qi.label}
                    <Typography variant="caption" style={{ marginLeft: 6 }}>Still to do: {qi.queued}</Typography>
                    <Typography variant="caption" style={{ marginLeft: 6 }}>Failed: {qi.poisoned}</Typography>
                  </Typography>
                </>
              }
            />)}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item className={classes.formitem}>
        <TextField placeholder='(optional)' disabled={!isAdmin || !queue} inputRef={register} label="Aantal" name="aantal" className={classes.field} />
      </Grid>
      <Grid item className={classes.formitem}>
        <Button disabled={!isAdmin} type="submit" variant="contained" color="primary">Retry</Button>
        {message ? <Typography variant='h6' color='primary'>{message}</Typography> : null}
      </Grid>
    </Grid>
  </form >
}
