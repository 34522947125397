import React, { FunctionComponent, createContext, useContext, useState, useEffect } from 'react';
import { GetUserContext } from './user.context';
import { DocumentSearchResult } from '../dto/DocumentSearchResult';
import { Facets } from '../dto/Facets';
import { OriginFromContentType, appConfig } from '../constants';
import { AuthenticationState } from 'react-aad-msal';
import { useSnackbar } from 'notistack';
import { GetFetchContext, Scope } from './fetch.context';
import { GetBusyContext } from './busy.context';

export type SearchContextType = {
  searchResult?: DocumentSearchResult;
  searchTerm: string | undefined;
  canSearch: boolean;
  searchTermChanged: (arg0?: string) => void;
  searchCanceled: () => void;
  facetChanged: (facet: string, value: string, active: boolean) => void;
  searchNextPage: () => void;
}

const SearchContext = createContext<SearchContextType>({
  searchResult: undefined,
  searchTerm: undefined,
  canSearch: false,
  searchTermChanged: () => { throw new Error() },
  searchCanceled: () => { throw new Error() },
  facetChanged: () => { throw new Error() },
  searchNextPage: () => { throw new Error() },
});

export type SearchContextProps = {
}

export const SearchProvider: FunctionComponent<SearchContextProps> = (props) => {
  const SearchApi: string = appConfig.ApiBaseUri + '/search';

  const snackbar = useSnackbar();
  const fetchContext = GetFetchContext();
  const userContext = GetUserContext();
  const busyContext = GetBusyContext();

  const [searchResult, setSearchResult] = useState<DocumentSearchResult>();
  const [searchPage, setSearchPage] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [canSearch, setCanSearch] = useState<boolean>(false);
  const [facets] = React.useState<Facets>({
    metadata_content_type: [],
    auteurs: [],
    "pdfmetadata/parent": [],
    "pdfmetadata/plaats": [],
    "pdfmetadata/jaar": [],
    "pdfmetadata/nrdvd": [],
    "pdfmetadata/nrrgd": [],
    "kcwmetadata/themas": [],
    "kcwmetadata/locatienummer": [],
    "kcwmetadata/locatiesoort": [],
    "kcwmetadata/literatuurcode": [],
    "kcwmetadata/oorspronkelijketechniek": []
  });

  const resetFacets = () => {
    facets.metadata_content_type = [];
    facets.auteurs = [];
    facets["pdfmetadata/parent"] = [];
    facets["pdfmetadata/plaats"] = [];
    facets["pdfmetadata/jaar"] = [];
    facets["pdfmetadata/nrdvd"] = [];
    facets["kcwmetadata/themas"] = [];
    facets["kcwmetadata/locatienummer"] = [];
    facets["kcwmetadata/locatiesoort"] = [];
    facets["kcwmetadata/literatuurcode"] = [];
    facets["kcwmetadata/oorspronkelijketechniek"] = [];
  }

  const facetChanged = (facet: string, value: string, active: boolean) => {
    let facetValues: Array<string>;

    switch (facet) {
      case 'Bron':
        facetValues = facets.metadata_content_type ?? [];
        break;
      case 'Auteurs':
        facetValues = facets.auteurs ?? [];
        if (searchResult) {
          searchResult["@search.facets"].auteurs.filter(item => item.value === value)[0].active = active;
        }
        break;
      case 'Bestand':
        facetValues = facets["pdfmetadata/parent"] ?? [];
        break;
      case 'Plaats':
        facetValues = facets["pdfmetadata/plaats"] ?? [];
        break;
      case 'Jaar':
        facetValues = facets["pdfmetadata/jaar"] ?? [];
        break;
      case 'Nr DVD':
        facetValues = facets["pdfmetadata/nrdvd"] ?? [];
        break;
      case 'Nr RGD':
        facetValues = facets["pdfmetadata/nrrgd"] ?? [];
        break;
      case 'Thema':
        facetValues = facets["kcwmetadata/themas"] ?? [];
        break;
      case 'Locatienummer':
        facetValues = facets["kcwmetadata/locatienummer"] ?? [];
        break;
      case 'Locatiesoort':
        facetValues = facets["kcwmetadata/locatiesoort"] ?? [];
        break;
      case 'Literatuurcode':
        facetValues = facets["kcwmetadata/literatuurcode"] ?? [];
        break;
      case 'Techniek':
        facetValues = facets["kcwmetadata/oorspronkelijketechniek"] ?? [];
        break;
      default:
        throw new Error('Facet unknown: ' + facet);
    }

    const idx = facetValues.indexOf(value);
    if ((idx >= 0) && !active) {
      facetValues.splice(idx, 1);
    } else if (active) {
      facetValues.push(value);
    }
    setSearchResult(undefined);
    setSearchPage(() => -1); // Little trick to force the search
  }

  const executeSearch = (__searchTerm: string, __searchPage: number, __facets: Facets, __order?: string): Promise<DocumentSearchResult> => {
    return new Promise<DocumentSearchResult>((resolve, reject) => {
      let searchInFile: string = '';
      const terms = __searchTerm.split('&');
      for (var i = 0; i < terms.length; i++) {
        if (terms[i].startsWith('file=')) {
          searchInFile = terms[i].substr(5);
          terms.splice(i);
        }
      }

      busyContext.push();
      fetchContext.post(SearchApi, Scope.User, {
        term: terms.join(' '),
        page: __searchPage,
        facets: __facets,
        order: __order,
        searchInFile: searchInFile
      }).then(response => response.json())
        .then((result: DocumentSearchResult) => {
          result.value.forEach(j => {
            j.origin = OriginFromContentType(j.document.metadata_content_type);
          })
          resolve(result);
        })
        .catch(err => reject(err))
        .finally(() => busyContext.pop());
    });
  }

  const doSearch = (): Promise<string | number | DocumentSearchResult | void> => {
    if (!searchTerm) return Promise.resolve();
    if (searchPage < 0) {
      setSearchPage(() => 0);
      return Promise.resolve();
    }

    busyContext.push();
    return executeSearch(searchTerm, searchPage, facets)
      .then((result) => {
        if (searchResult) {
          for (var i = searchResult.value.length - 1; i >= 0; i--) {
            result.value.unshift(searchResult.value[i]);
          }
        }

        facets.metadata_content_type?.forEach(zoekfacet => {
          const item = result["@search.facets"].metadata_content_type.find(facet => facet.value === zoekfacet);
          if (item) item.active = true;
        });
        facets.auteurs?.forEach(auteur => {
          const item = result["@search.facets"].auteurs.find(facet => facet.value === auteur);
          if (item) item.active = true;
        });
        facets["pdfmetadata/parent"]?.forEach(zoekfacet => {
          const item = result["@search.facets"]["pdfmetadata/parent"].find(facet => facet.value === zoekfacet);
          if (item) item.active = true;
        });
        facets["pdfmetadata/jaar"]?.forEach(zoekfacet => {
          const item = result["@search.facets"]["pdfmetadata/jaar"].find(facet => facet.value === zoekfacet);
          if (item) item.active = true;
        });
        facets["pdfmetadata/nrrgd"]?.forEach(zoekfacet => {
          const item = result["@search.facets"]["pdfmetadata/nrrgd"].find(facet => facet.value === zoekfacet);
          if (item) item.active = true;
        });
        facets["pdfmetadata/nrdvd"]?.forEach(zoekfacet => {
          const item = result["@search.facets"]["pdfmetadata/nrdvd"].find(facet => facet.value === zoekfacet);
          if (item) item.active = true;
        });
        facets["pdfmetadata/plaats"]?.forEach(zoekfacet => {
          const item = result["@search.facets"]["pdfmetadata/plaats"].find(facet => facet.value === zoekfacet);
          if (item) item.active = true;
        });
        facets["kcwmetadata/themas"]?.forEach(zoekfacet => {
          const item = result["@search.facets"]["kcwmetadata/themas"].find(facet => facet.value === zoekfacet);
          if (item) item.active = true;
        });
        facets["kcwmetadata/locatienummer"]?.forEach(zoekfacet => {
          const item = result["@search.facets"]["kcwmetadata/locatienummer"].find(facet => facet.value === zoekfacet);
          if (item) item.active = true;
        });
        facets["kcwmetadata/locatiesoort"]?.forEach(zoekfacet => {
          const item = result["@search.facets"]["kcwmetadata/locatiesoort"].find(facet => facet.value === zoekfacet);
          if (item) item.active = true;
        });
        facets["kcwmetadata/literatuurcode"]?.forEach(zoekfacet => {
          const item = result["@search.facets"]["kcwmetadata/literatuurcode"].find(facet => facet.value === zoekfacet);
          if (item) item.active = true;
        });
        facets["kcwmetadata/oorspronkelijketechniek"]?.forEach(zoekfacet => {
          const item = result["@search.facets"]["kcwmetadata/oorspronkelijketechniek"].find(facet => facet.value === zoekfacet);
          if (item) item.active = true;
        });

        setSearchResult(result);
        return result;
      })
      .catch((err: Error) => snackbar.enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => busyContext.pop());
  }

  useEffect(() => {
    setCanSearch(() => userContext.authenticationState === AuthenticationState.Authenticated);
  }, [userContext.authenticationState]);

  useEffect(() => {
    resetFacets();
    setSearchResult(undefined);
    if (!searchTerm) return;
    setSearchPage(() => -1); // Little trick to force the search
    // eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    doSearch();
    // eslint-disable-next-line      
  }, [searchPage]);

  const activeProps: SearchContextType = {
    searchResult: searchResult,
    searchTerm: searchTerm,
    canSearch: canSearch,
    searchCanceled: () => {
      resetFacets();
      setSearchTerm(() => undefined);
      setSearchResult(() => undefined);
      setSearchPage(() => 0);
    },
    searchTermChanged: (arg0?: string) => setSearchTerm(() => arg0 ?? ''),
    facetChanged: facetChanged,
    searchNextPage: () => setSearchPage(sp => sp + 1),
  }

  return (
    <SearchContext.Provider value={activeProps}>
      {props.children}
    </SearchContext.Provider>
  );

};

export const GetSearchContext = (): SearchContextType => {
  return useContext(SearchContext);
};
