import React, { FunctionComponent, createContext, useContext } from "react"
import { GetUserContext } from "./user.context";
import { useSnackbar } from "notistack";

export enum Scope {
  Anonymous,
  User,
  KCW,
  PDF,
  SuperUser,
  Admin
}

const NotImplementedResponse = (): Promise<Response> => new Promise<Response>((resolve, reject) => {
  reject(new Error('Not implemented'));
});


export type FetchContextType = {
  get: (url: string, scope: Scope) => Promise<Response>;
  post: (url: string, scope: Scope, body?: any) => Promise<Response>;
  put: (url: string, scope: Scope, body: any) => Promise<Response>;
  patch: (url: string, scope: Scope, body: any) => Promise<Response>;
  delete: (url: string, scope: Scope, body: any) => Promise<Response>;
}

export const FetchContext = createContext<FetchContextType>({
  get: (url: string, scope: Scope) => NotImplementedResponse(),
  post: (url: string, scope: Scope, body: any) => NotImplementedResponse(),
  put: (url: string, scope: Scope, body: any) => NotImplementedResponse(),
  patch: (url: string, scope: Scope, body: any) => NotImplementedResponse(),
  delete: (url: string, scope: Scope, body: any) => NotImplementedResponse()
});

export type FetchProviderProps = {
  token: string;
}

const getHeaders = (scope: Scope, token: string): HeadersInit => {
  if (scope !== Scope.Anonymous) {
    return {
      Authorization: 'Bearer ' + token,
      ContentType: 'application/json'
    }
  }
  return {
    ContentType: 'application/json'
  }
}

export const FetchProvider: FunctionComponent<FetchProviderProps> = (props) => {
  const userContext = GetUserContext();
  const snackbar = useSnackbar();

  const doFetch = (method: string, url: string, scope: Scope, body?: any, retry: boolean = true): Promise<Response> =>
    new Promise<Response>((resolve, reject) => {
      let requestInit: RequestInit;
      if (method.toUpperCase() === 'GET') {
        requestInit = {
          method: method,
          headers: getHeaders(scope, props.token)
        };
      } else {
        if (body) {
          requestInit = {
            method: method,
            headers: getHeaders(scope, props.token),
            body: JSON.stringify(body)
          }
        } else {
          requestInit = {
            method: method,
            headers: getHeaders(scope, props.token)
          }
        }
      }

      console.log(`${method}: ${url}`);

      fetch(url, requestInit)
        .then((response: Response) => {
          if (!response.ok) {
            reject(new Error('Error ' + method + ':' + url));
          }
          resolve(response);
        })
        .catch(err => {
          console.log(err);
          if (retry) {
            snackbar.enqueueSnackbar('Getting new token...', { variant: 'info' });
            userContext.refreshToken()
              .then(() => doFetch(method, url, scope, body, false))
              .then(response => {
                snackbar.closeSnackbar();
                resolve(response);
              })
              .catch(errretry => reject(errretry));
          } else {
            reject(err);
          }
        });
});

const _get = (url: string, scope: Scope): Promise<Response> => doFetch('GET', url, scope, {});
const _post = (url: string, scope: Scope, body?: any): Promise<Response> => doFetch('POST', url, scope, body);
const _put = (url: string, scope: Scope, body: any) => doFetch('PUT', url, scope, body);
const _patch = (url: string, scope: Scope, body: any) => doFetch('PATCH', url, scope, body);
const _delete = (url: string, scope: Scope, body: any) => doFetch('DELETE', url, scope, body);

const value: FetchContextType = {
  get: _get,
  post: _post,
  put: _put,
  patch: _patch,
  delete: _delete
}

return <FetchContext.Provider value={value}>
  {props.children}
</FetchContext.Provider>
}

export const GetFetchContext = (): FetchContextType => {
  return useContext(FetchContext);
}