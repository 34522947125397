import { FunctionComponent } from "react";
import React from "react";
import { useGlobalStyles } from "../styles";
import { Typography, Divider, ButtonGroup } from "@material-ui/core";
import { GetShoppingContext } from "../contexts/shopping.context";
import { Link } from "@reach/router";
import { SaveContentItem } from './SaveContentItem';

export type BrondocumentProps = {
  title?: string;
  href?: string;
  content?: string;
}

export const BrondocumentItem: FunctionComponent<BrondocumentProps> = (props) => {
  const classes = useGlobalStyles();
  const shoppingContext = GetShoppingContext();

  const href = new URL(props.href ?? '');

  return (<>
    <Typography variant='h6' className={classes.title}>
      {props?.title && props.href ?
        <>
          <ButtonGroup className={classes.buyButton}>{shoppingContext.buttonFor(props.href, props.title)}</ButtonGroup>
          <Link to={href.pathname}>
            {props.title}
          </Link>
        </>
        : null}
    </Typography>

    {props.content ? <SaveContentItem content={props.content} /> : null}

    <Divider />
  </>);
}
