import React, { FunctionComponent, useEffect } from "react";
import { useNavigate } from "@reach/router";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SearchBar from 'material-ui-search-bar';
import { GetSearchContext } from "../contexts/search.context";

const useStyles = makeStyles((theme) =>
  createStyles({
    inputRoot: {
      width: '100%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    }
  }),
);
type SearchBoxProps = {}

export const SearchBox: FunctionComponent<SearchBoxProps> = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const searchContext = GetSearchContext();
  const [searchText, setSearchText] = React.useState<string | undefined>(searchContext.searchTerm);

  const handleRequestSearch = () => {
    if (searchText) {
      navigate('/search/' + searchText);
    }
  }

  useEffect(() => {
    setSearchText(searchContext.searchTerm);
  }, [searchContext.searchTerm])

  const handleCancelSearch = () => {
    setSearchText(() => undefined);
    searchContext.searchCanceled();
  }

  return (
    <SearchBar
      className={classes.inputRoot}
      disabled={!searchContext.canSearch}
      value={searchText}
      onChange={(t) => setSearchText(t)}
      onRequestSearch={() => handleRequestSearch()}
      onCancelSearch={() => handleCancelSearch()}
    />
  )
}