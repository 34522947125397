import React, { FunctionComponent, useEffect } from "react";
import { AppFrameworkProvider } from '../contexts/appframework.context';
import { SearchResultList } from '../navigation/searchResultList';
import { GetSearchContext } from '../contexts/search.context';

type SearchProps = {
  path: string;
  search?: string;
}

export const Search: FunctionComponent<SearchProps> = (props) => {
  const searchContext = GetSearchContext();

  useEffect(() => {
    searchContext.searchTermChanged(props.search);
  }, [searchContext, props.search]);

  return (
    <AppFrameworkProvider>
      <SearchResultList />
    </AppFrameworkProvider>
  );

}