import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import React, { FunctionComponent, useState, useEffect } from "react";
import { StatisticsDto, StatisticsPageDto } from "../dto/StatisticsDto";
import { useSnackbar } from "notistack";
import { IconButton, TableContainer, Table, TableHead, TableCell, makeStyles, TableRow, TablePagination, withStyles, Theme, createStyles, TableBody, Typography, TextField, Button, Grid } from "@material-ui/core";
import clsx from 'clsx';
import { GetFetchContext, Scope } from '../contexts/fetch.context';
import { appConfig } from '../constants';
import { Link } from '@reach/router';
import { GetBusyContext } from '../contexts/busy.context';
import { GetUserContext } from '../contexts/user.context';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  errorline: {
  },
  tableContainer: {
    padding: theme.spacing(2)
  },
  iconcolumn: {
  },
  actioncolumn: {
    width: 100
  },
  numbercolumn: {
    width: 70
  },
  error: {
  },
  flexGrow: {
    flexGrow: 1
  },
  flexDontGrow: {
    flexGrow: 0
  }
}));

type StatisticsProps = {
  pagenumber?: number;
  pagecount?: number;
  prefix?: string;
}

type StatisticsLineProps = {
  line: number
  page: StatisticsPageDto;
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&': {
      },
      '&:nth-of-type(odd)': {
      }
    }
  }),
)(TableRow);

export const StatisticsLine: FunctionComponent<StatisticsLineProps> = (props) => {
  const classes = useStyles();
  const fetchContext = GetFetchContext();
  const snackbar = useSnackbar();
  const busyContext= GetBusyContext();

  const errorfree = (props.page.paginasInDocument === props.page.metadataPaginaCount) &&
    (props.page.paginasInDocument === props.page.paginaCount) &&
    (props.page.paginasInDocument === props.page.previewsCount);

  const updateMetadata = (method: string) => {
    if (busyContext.busy) return;

    busyContext.push();
    const url: string = appConfig.ApiBaseUri + '/updatemetadata/' + props.page.naam;
    var promise: Promise<any>;
    switch (method) {
      case 'POST':
        promise = fetchContext.post(url, Scope.Admin, {});
        break;
      case 'PATCH':
        promise = fetchContext.patch(url, Scope.Admin, {});
        break;
      case 'PUT':
        promise = fetchContext.put(url, Scope.Admin, {});
        break;
      default:
        throw new Error('Unknown method ' + method);
    }

    promise
      .then(() => snackbar.enqueueSnackbar('Opdracht wordt verwerkt', {variant: 'info'}))
      .catch(err => snackbar.enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => busyContext.pop());
  }

  return (
    <StyledTableRow>
      <StyledTableCell className={clsx(classes.numbercolumn)} align="right">
        {props.line}
      </StyledTableCell>
      <StyledTableCell className={clsx(classes.actioncolumn)}>
        <Button className={clsx(!errorfree ? classes.error : null)} variant={errorfree ? "outlined" : "contained"} size="small" onClick={() => updateMetadata('POST')}>Opnieuw</Button>
      </StyledTableCell>
      <StyledTableCell className={clsx(classes.actioncolumn)}>
        <Button className={clsx(!errorfree ? classes.error : null)} variant={errorfree ? "outlined" : "contained"} size="small" onClick={() => updateMetadata('PATCH')}>Vervolg</Button>
      </StyledTableCell>
      <StyledTableCell className={clsx(classes.actioncolumn)}>
        <Button className={clsx(!errorfree ? classes.error : null)} variant={errorfree ? "outlined" : "contained"} size="small" onClick={() => updateMetadata('PUT')}>Ververs</Button>
      </StyledTableCell>
      <StyledTableCell scope="row" className={clsx(!errorfree ? classes.error : null)}>
        <Link to={`/search/file=${props.page.naam}`}>
          {props.page.naam}
        </Link>
      </StyledTableCell>
      <StyledTableCell className={clsx(classes.numbercolumn)} align="right">{props.page.paginasInDocument}</StyledTableCell>
      <StyledTableCell className={clsx(classes.numbercolumn, (props.page.paginasInDocument !== props.page.metadataPaginaCount) ? classes.error : null)} align="right">{props.page.metadataPaginaCount}</StyledTableCell>
      <StyledTableCell className={clsx(classes.numbercolumn, (props.page.paginasInDocument !== props.page.paginaCount) ? classes.error : null)} align="right">{props.page.paginaCount}</StyledTableCell>
      <StyledTableCell className={clsx(classes.numbercolumn, (props.page.paginasInDocument !== props.page.highresPreviewsCount) ? classes.error : null)} align="right">{props.page.highresPreviewsCount}</StyledTableCell>
      <StyledTableCell className={clsx(classes.numbercolumn, (props.page.paginasInDocument !== props.page.previewsCount) ? classes.error : null)} align="right">{props.page.previewsCount}</StyledTableCell>
    </StyledTableRow>
  )
}

export const StatisticsPage: FunctionComponent<StatisticsProps> = (props) => {
  const classes = useStyles();

  const busyContext= GetBusyContext();
  const fetchContext = GetFetchContext();
  const snackbar = useSnackbar();
  const userContext = GetUserContext();
  const [statistics, setStatistics] = useState<StatisticsDto>();
  const [pageNumber, setPageNumber] = useState<number>(props.pagenumber ?? 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.pagecount ?? 15);
  const [newPage, setNewPage] = useState<number>(pageNumber);
  const [prefix, setPrefix] = useState<string>(props.prefix ?? '');
  const [newPrefix, setNewPrefix] = useState<string>(prefix);
  const [refreshCounter, setRefreshCounter] = useState<number>(0);

  useEffect(() => {
    if (!userContext.apiToken) return;
    
    const url: string = appConfig.ApiBaseUri + '/statistics/' + rowsPerPage + '/' + (pageNumber) + '/' + prefix;
    busyContext.push();
    fetchContext.get(url, Scope.SuperUser)
      .then(response => response.json())
      .then((data: StatisticsDto) => {
        setStatistics(data);
      })
      .catch((err: Error) => snackbar.enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => busyContext.pop());
    // eslint-disable-next-line
  }, [userContext.apiToken, props, pageNumber, rowsPerPage, prefix, refreshCounter]);

  useEffect(() => {
    setNewPage(pageNumber);
  }, [pageNumber]);

  if (!statistics) return null;
  if (!statistics.pdfPages) return null;

  const getMore = (event: unknown, page: number) => {
    setPageNumber(page);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPageNumber(0);
  };
  const handleOnKeyDownPage = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (newPage * rowsPerPage > statistics.totalCount) {
        setNewPage(0)
      }
      else {
        setPrefix(newPrefix);
        setPageNumber(newPage);
      }
    }
  }
  const handleOnKeyDownPrefix = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setPrefix(newPrefix);
      setPageNumber((newPage * rowsPerPage > statistics.totalCount) ? 0 : newPage);
    }
  }
  const handleOnChangePage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPage(+event.currentTarget.value);
  }
  const handleOnChangePrefix = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPrefix(event.currentTarget.value);
  }

  return <div className={classes.root}>
    <Typography variant="h1">Statistieken</Typography>
    {statistics?.pdfPages ?
      <TableContainer className={classes.tableContainer}>
        <Grid container>
          <Grid item className={classes.flexDontGrow}>
            <IconButton onClick={() => setRefreshCounter(rc => rc + 1)}>
              <ReplayOutlinedIcon></ReplayOutlinedIcon>
            </IconButton>
          </Grid>
          <Grid item className={classes.flexDontGrow}>
            <TextField label='Paginanummer' value={newPage} onChange={handleOnChangePage} onKeyDown={handleOnKeyDownPage} />
          </Grid>
          <Grid item className={classes.flexDontGrow}>
            <TextField label='Bestandsnaam' value={newPrefix} onChange={handleOnChangePrefix} onKeyDown={handleOnKeyDownPrefix} />
          </Grid>
          <Grid item className={classes.flexGrow}>
            <TablePagination
              title='Aantal per pagina'
              labelRowsPerPage='Aantal per pagina'
              rowsPerPageOptions={[15, 50, 100]}
              component="div"
              count={statistics.totalCount}
              rowsPerPage={rowsPerPage}
              page={pageNumber}
              onPageChange={getMore}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
        <Table stickyHeader size="small" aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.numbercolumn} align="right">#</StyledTableCell>
              <StyledTableCell className={classes.actioncolumn} align="center">Splitsen</StyledTableCell>
              <StyledTableCell className={classes.actioncolumn} align="center">Vervolgen</StyledTableCell>
              <StyledTableCell className={classes.actioncolumn} align="center">Metadata</StyledTableCell>
              <StyledTableCell>PDF bestand</StyledTableCell>
              <StyledTableCell className={classes.numbercolumn} align="right">Pagina's in document</StyledTableCell>
              <StyledTableCell className={classes.numbercolumn} align="right">Pagina's volgens metadata</StyledTableCell>
              <StyledTableCell className={classes.numbercolumn} align="right">Pagina's gegenereerd</StyledTableCell>
              <StyledTableCell className={classes.numbercolumn} align="right">Aantal high res previews</StyledTableCell>
              <StyledTableCell className={classes.numbercolumn} align="right">Aantal previews</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statistics.pdfPages.map((p, index) =>
              <StatisticsLine key={index} page={p} line={(rowsPerPage * pageNumber) + index} />
            )}
          </TableBody>
        </Table>
        <TablePagination
          title='Aantal per pagina'
          labelRowsPerPage='Aantal per pagina'
          rowsPerPageOptions={[15, 50, 100]}
          component="div"
          count={statistics.totalCount}
          rowsPerPage={rowsPerPage}
          page={pageNumber}
          onPageChange={getMore}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      : null}
  </div>
}
