import React, { FunctionComponent, createContext, useContext } from 'react';
import DOMPurify from 'dompurify'
import { KcwMetadataDto } from '../dto/KcwMetadataDto';
import { GetFetchContext, Scope } from './fetch.context';
import { useSnackbar } from 'notistack';
import { appConfig } from '../constants';

export type TopicstoreContextType = {
  load: (topicGuid: string, aantalBrondocumenten: number) => Promise<LoadTopicResult>;
  loadMore: (result: LoadTopicResult, aantalBrondocumenten: number) => Promise<LoadTopicResult>;
}

export const TopicstoreContext = createContext<TopicstoreContextType>({
  load: (topicGuid: string, aantalBrondocumenten: number): Promise<LoadTopicResult> => Promise.resolve({}),
  loadMore: (result: LoadTopicResult, aantalBrondocumenten: number): Promise<LoadTopicResult> => Promise.resolve({})
})

export type TopicstoreProviderProps = {
}

type BrondocumentProps = {
  title?: string;
  href?: string;
  content?: string;
}

export type LoadTopicResult = {
  content?: string;
  metadata?: KcwMetadataDto;
  brondocumenten?: Array<BrondocumentProps>;
}

export const TopicstoreProvider: FunctionComponent<TopicstoreProviderProps> = (props) => {
  const fetchContext = GetFetchContext();
  const snackbar = useSnackbar();
  const topicPrefix = appConfig.TopicContainerUri;
  // const topicPrefix = appConfig.ApiBaseUri + '/topic/';
  const resourcesPrefix = appConfig.ResourcesContainerUri;

  const load = (topicPage: string, aantalBrondocumenten: number): Promise<LoadTopicResult> => {
    const getContentUrl = topicPrefix + topicPage;

    return new Promise<LoadTopicResult>((resolve, reject) => {
      fetchContext.get(getContentUrl, Scope.Anonymous)
        .then(response => response.text())
        .then(data => {
          let response: LoadTopicResult = {};
          var parser = new DOMParser();
          var kcwdocument = parser.parseFromString(data, "text/html");

          var images = kcwdocument.getElementsByTagName("img");
          for (let i = 0; i < images.length; i++) {
            const url = new URL(images[i].src);
            const base = url.protocol + '//' + url.host;
            images[i].src = images[i].src.replace(base, resourcesPrefix);
          }

          // Changed addressing of links
          var anchors = kcwdocument.getElementsByTagName("a");
          for (let i = 0; i < anchors.length; i++) {
            // Change resources location
            const href = new URL(anchors[i].href);
            const imgoldbase = href.protocol + '//' + href.host + "/resources/";
            const imgnewbase = resourcesPrefix + "/resources/";
            anchors[i].href = anchors[i].href.replace(imgoldbase, imgnewbase);
          }

          // Changed addressing of topics
          if (aantalBrondocumenten > 0) {
            var brondocumenten = new Array<BrondocumentProps>();
            ['Archiefstuk', 'Literatuur', 'Interieur'].forEach(tt => {
              var list = kcwdocument.evaluate(`/html/body//ul[@class='Brondocument']/li[@class='` + tt + `']/a`, kcwdocument);
              var listNode = list.iterateNext();
              while (listNode) {
                var anchorNode = listNode as HTMLAnchorElement;
                brondocumenten.push({
                  title: anchorNode.textContent ?? anchorNode.href,
                  href: anchorNode.href
                });
                listNode = list.iterateNext();
              }
            });

            // Aanpakken bug in OA
            const removeNodes = new Array<Node>();
            ['Archiefstuk', 'Literatuur', 'Interieur'].forEach(tt => {
              var verkeerderelaties = kcwdocument.evaluate(`/html/body//ul[@class='Heeft_betrekking_op']/li[@class='` + tt + `']/a`, kcwdocument);
              var relNode = verkeerderelaties.iterateNext();
              while (relNode) {
                var anchorNode = relNode as HTMLAnchorElement;
                brondocumenten.push({
                  title: anchorNode.textContent ?? anchorNode.href,
                  href: anchorNode.href
                });
                removeNodes.push(relNode);
                relNode = verkeerderelaties.iterateNext();
              }
            });
            removeNodes.forEach(n => n?.parentNode?.parentNode?.removeChild(n.parentNode));

            const removeParentNodes = new Array<Node>();
            ['Brondocument', 'Heeft_betrekking_op'].forEach(rt => {
              const heeftBetrekkingOp = kcwdocument.evaluate(`/html/body//ul[@class='` + rt + `']`, kcwdocument);
              var relNode = heeftBetrekkingOp.iterateNext();
              if (relNode) {
                if (!relNode.hasChildNodes) {
                  removeParentNodes.push(relNode);
                }
                relNode.parentNode?.removeChild(relNode);
              }
            });
            removeParentNodes.forEach(n => n?.parentNode?.removeChild(n));

            brondocumenten.sort((bd1, bd2) => {
              if (!bd1?.title || !bd2?.title) return 0;
              return bd1.title.toLocaleLowerCase() > bd2.title.toLocaleLowerCase() ? 1 : -1;
            });

            response.brondocumenten = brondocumenten;

          } else {
            var linkedList = kcwdocument.evaluate(`/html/body/section[@class='linkedlists']`, kcwdocument);
            var node = linkedList.iterateNext();
            if (node) {
              node.parentNode?.removeChild(node);
            }
          }

          const metadataNodes = kcwdocument.evaluate("/html/body/section[@class='metadata']/code", kcwdocument);
          const metadataNode = metadataNodes.iterateNext();

          if (metadataNode) {
            var json = metadataNode.textContent;
            if (json) {
              response.metadata = JSON.parse(json) as KcwMetadataDto;
            }
          }

          response.content = DOMPurify.sanitize(kcwdocument.documentElement.outerHTML,
            {
              ALLOWED_URI_REGEXP: /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|cid|xmpp|javascript):|[^a-z]|[a-z+.-]+(?:[^a-z+.\-:]|$))/i,
              //ADD_ATTR: ["data-highres"],
            });

          resolve(response);
        })
        .catch(err => snackbar.enqueueSnackbar(err.message, { variant: 'error' }));
    });
  }

  const loadMore = (result: LoadTopicResult, aantalBrondocumenten: number): Promise<LoadTopicResult> => {
    if (!result?.brondocumenten) return Promise.resolve(result);

    let counter = 0;
    // Zoek de eerste zonder content
    while (counter < result.brondocumenten.length && result.brondocumenten[counter].content) {
      counter++;
    }

    return new Promise<LoadTopicResult>((resolve, reject) => {
      if (!result?.brondocumenten) return Promise.resolve(result);
      const promises = new Array<Promise<any>>();
      const max = Math.min(result.brondocumenten.length, counter + aantalBrondocumenten);
      for (let i = counter; i < max; i++) {
        const item = result.brondocumenten[i];
        if (!item.content && item.href) {
          const url = new URL(item.href);
          const topicPage = url.pathname.replace('/topics/', '');
          promises.push(load(topicPage, 0)
            .then((r) => item.content = r.content)
          );
        }
      }

      Promise.all(promises)
        .then(() => resolve(result))
        .catch(err => snackbar.enqueueSnackbar(err.message, { variant: 'error' }));
    });
  }

  const activeProps: TopicstoreContextType = {
    load: load,
    loadMore: loadMore
  };

  return (
    <TopicstoreContext.Provider value={activeProps}>
      {props.children}
    </TopicstoreContext.Provider>
  );
};

export const GetTopicstoreContext = (): TopicstoreContextType => {
  return useContext(TopicstoreContext);
};