import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import { Router } from '@reach/router';
import { PageNotFound } from './pages/PageNotFound';
import 'typeface-roboto';
import { SnackbarProvider } from 'notistack';
import { AzureAD } from 'react-aad-msal';
import { authProvider } from './authentication';
import { aadCallbackInfo } from './dto/aadCallbackInfo';
import { UserProvider } from './contexts/user.context';
import { BusyProvider } from './contexts/busy.context';
import { Admin } from './admin/admin';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SearchProvider } from './contexts/search.context';
import { KcwPage } from './pages/kcwpage';
import { FullScreenProvider } from './contexts/fullscreen.context';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { AppInsightsContextProvider } from './ApplicationInsights/ApplicationInsightsContext';
import { Chapter } from './pages/chapter';
import { Search } from './navigation/search';
import { Shop } from './pages/shop';
import { ShoppingProvider } from './contexts/shopping.context';
import { appConfig } from './constants';
import { TopicstoreProvider } from './contexts/topicstore.context';
import { CookieConsentProvider} from './contexts/cookieconsent.context';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  }
});

ReactDOM.render(
  <AzureAD provider={authProvider} forceLogin={false}>
    {
      (callbackInfo: aadCallbackInfo) => {

        if (callbackInfo.authenticationState === 'Unauthenticated') {
          // Trick, since it seems like the library is not up to date with the callback from the server
          var theError: any = callbackInfo.error;
          if (theError?.errorMessage?.startsWith('AADB2C90118: The user has forgotten their password.')) {
            // Redirect to the password reset link
            const resetPasswordAuthority = appConfig.ResetPasswordUri;
            if (resetPasswordAuthority) {
              authProvider.login(
                {
                  authority: resetPasswordAuthority
                }
              );
            }
          }
        }

        return (
          <AppInsightsContextProvider>
            <SnackbarProvider maxSnack={3}>
              <CookieConsentProvider>
                <BusyProvider>
                  <UserProvider authProvider={authProvider} aadCallbackInfo={callbackInfo}>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <FullScreenProvider>
                        <TopicstoreProvider>
                          <SearchProvider>
                            <ShoppingProvider>
                              <Router basepath={appConfig.PublicUri}>
                                <App path="/" />
                                <App path="/logout" />
                                <Search path="/search/:search" />
                                <Chapter path="/chapter/:parent" />
                                <Admin path="/administrator" />
                                <Admin path="/administrator/statistics" pagecount={15} pagenumber={0} page="statistics" />
                                <Admin path="/administrator/statistics/:pagecount/" pagenumber={0} page="statistics" />
                                <Admin path="/administrator/statistics/:pagecount/:pagenumber" page="statistics" />
                                <Admin path="/administrator/statistics/:pagecount/:pagenumber/:prefix" page="statistics" />
                                <Admin path="/administrator/:page" />
                                <KcwPage path="/topics/:page" />
                                <Shop path="/shop" />
                                <PageNotFound default={true} />
                              </Router>
                            </ShoppingProvider>
                          </SearchProvider>
                        </TopicstoreProvider>
                      </FullScreenProvider>
                    </ThemeProvider>
                  </UserProvider>
                </BusyProvider>
              </CookieConsentProvider>
            </SnackbarProvider>
          </AppInsightsContextProvider>
        );
      }
    }
  </AzureAD>, document.getElementById('root'));

serviceWorker.unregister();