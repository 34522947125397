import React, { FunctionComponent } from "react";
import { TocView } from '../navigation/tocview';
import { AppFrameworkProvider } from '../contexts/appframework.context';

type ChapterProps = {
  path: string;
  parent?: string;
}

export const Chapter: FunctionComponent<ChapterProps> = (props) => {
  return (
    <AppFrameworkProvider>
      {props.parent ? <TocView parent={props.parent} /> : null}
    </AppFrameworkProvider>
  );
}