import Backdrop from '@material-ui/core/Backdrop';
import React, { createContext, useContext, FunctionComponent } from "react";
import { makeStyles } from '@material-ui/core';

export type ImageInfo = {
    src: string;
    alt: string;
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 9998
    },
    image: {
        width: 'auto',
        height: '100%'
    }
}));

export type FullScreenContextType = {
    setImage: (img?: ImageInfo) => void;
}

const FullScreenContext = createContext<FullScreenContextType>({
    setImage: (img?: ImageInfo) => { throw new Error(); }
})

type FullScreenContextProps = {}

export const FullScreenProvider: FunctionComponent<FullScreenContextProps> = (props) => {
    const classes = useStyles();
    const [image, setImage] = React.useState<ImageInfo>();

    const value: FullScreenContextType = {
        setImage: (src?: ImageInfo) => setImage(src)
    }

    return <FullScreenContext.Provider value={value}>
        {props.children}
        <Backdrop onClick={() => setImage(undefined)} className={classes.backdrop} open={image !== undefined}><img src={image?.src} alt={image?.alt} className={classes.image}></img></Backdrop>
    </FullScreenContext.Provider>
}

export const GetFullscreenContext = (): FullScreenContextType => {
    return useContext(FullScreenContext);
};
