import React, { FunctionComponent, createContext, useContext } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useGlobalStyles } from '../styles';

export type BusyContextType = {
  busy: boolean;
  push: () => void;
  pop: () => void;
}

export const BusyContext = createContext<BusyContextType>({
  busy: false,
  push: () => { throw new Error() },
  pop: () => { throw new Error() },
})

export type BusyProviderProps = {
}

export const BusyProvider: FunctionComponent<BusyProviderProps> = (props) => {
  const classes = useGlobalStyles();
  const [busyCounter, setBusyCounter] = React.useState<number>(0);
  const value = {
    busy: (busyCounter > 0),
    push: () => setBusyCounter(bc => bc + 1),
    pop: () => setBusyCounter(bc => Math.max(0, bc - 1))
  }

  return (
    <BusyContext.Provider value={value}>
      {props.children}
      <Backdrop className={classes.backdrop} open={busyCounter > 0}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </BusyContext.Provider>
  );
};

export const GetBusyContext = (): BusyContextType => {
  return useContext(BusyContext);
};