import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { FunctionComponent } from "react";
import { FormGroup, Typography, FormControlLabel, Switch, makeStyles, Container } from "@material-ui/core";
import { FacetResult } from "../dto/FacetResult";
import { OriginFromContentType } from "../constants";
import { GetSearchContext } from "../contexts/search.context";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

const useStyles = makeStyles((theme) => ({
    nonCollapsable: {
        paddingLeft: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    title: {
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    label: {
        placeSelf: 'flex-start',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    formcontrol: {
        marginLeft: theme.spacing(1)
    }
}));

export type FacetResultsProps = {
    title: string;
    facets: Array<FacetResult>;
    collapsable: boolean;
}

export const FacetResultsList: FunctionComponent<FacetResultsProps> = (props: FacetResultsProps) => {
    const classes = useStyles();
    const searchContext = GetSearchContext();
    const handleFacetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchContext.facetChanged(props.title, event.target.id, event.target.checked);
    };
    const sortedFacets = props.facets.sort((f1, f2) => f1.value.trim().toUpperCase().localeCompare(f2.value.trim().toUpperCase()));
    const [expanded, setExpanded] = React.useState<boolean>(sortedFacets.find(f => f.active) !== undefined)

    if (!props.facets) return null;
    if (props.facets.length === 0) return null;

    if (!props.collapsable) {
        return (
            <Container className={classes.nonCollapsable}>
                <Typography variant="subtitle1" className={classes.title}>{props.title}</Typography>
                <FormGroup>
                    {sortedFacets
                        .map((facet, index) =>
                            <FormControlLabel key={index}
                                control={<Switch size="small" color='primary' id={facet.value} onChange={handleFacetChange} checked={facet.active}></Switch>}
                                labelPlacement='end'
                                label={
                                    <Typography variant='body2' className={classes.label}>
                                        {OriginFromContentType(facet.value) + (facet.count > 0 ? ' (' + facet.count + ')' : '')}
                                    </Typography>
                                }
                            />
                        )}
                </FormGroup>
            </Container>
        );
    }

    return (
        sortedFacets.length > 0 ?
            <ExpansionPanel TransitionProps={{ unmountOnExit: true }} expanded={expanded} onChange={(event: object, expanded: boolean) => setExpanded(expanded)}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1" className={classes.title}>{props.title} ({sortedFacets.length})</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <FormGroup>
                        {sortedFacets
                            .map((facet, index) =>
                                <FormControlLabel key={index}
                                    control={<Switch color='primary' size="small" id={facet.value} onChange={handleFacetChange}
                                        checked={facet.active}></Switch>}
                                    labelPlacement='end'
                                    label={
                                        <Typography variant='body2' className={classes.label}>
                                            {OriginFromContentType(facet.value) + (facet.count > 0 ? ' (' + facet.count + ')' : '')}
                                        </Typography>
                                    }
                                />
                            )}
                    </FormGroup>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            : null
    )
}
