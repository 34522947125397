import { ShoppingCartItem } from "./ShoppingCartItem"

export type ShoppingCart = {
  email: string;
  basePath: string;
  items: Array<ShoppingCartItem>;
  rowKey: string;
  version: string;
}

export const ShoppingCartNull : ShoppingCart = {email: '', basePath: '', items: [], rowKey: '', version: ''};