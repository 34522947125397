import React, { FunctionComponent } from "react";
import { Typography, Grid, makeStyles } from "@material-ui/core";
import InfiniteScroll from 'react-infinite-scroll-component';
import { SearchResultItemCard } from './searchResultItemCard';
import { GetSearchContext } from "../contexts/search.context";
import { MainNav } from "./mainNav";

const useStyles = makeStyles((theme) => ({
  parent: {
    height: 'calc(100vh - 64px)',
    margin: 0,
    width: '100%'
  },
  scrollleft: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  scrollright: {
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden'
  }
}));

export type SearchResultProps = {
}

export const SearchResultList: FunctionComponent<SearchResultProps> = (props: SearchResultProps) => {
  const classes = useStyles();
  const searchContext = GetSearchContext();
  const currentlyLoaded = (searchContext.searchResult?.value.length ?? 0);
  const rightColumnRef = React.createRef<any>();

  const msg = searchContext.searchResult ? 
                searchContext.searchResult["@odata.count"] + (searchContext.searchResult["@odata.count"] > 1 ? ' pagina\'s ' : ' pagina ') + 'gevonden'
                : null

  return (
    <Grid container alignItems='flex-start' spacing={3} className={classes.parent}>
      <Grid item xs={2} className={classes.scrollleft}>
        <MainNav />
      </Grid>
      <Grid item xs={10} className={classes.scrollright} ref={rightColumnRef} id='parentdiv'>
        <Typography variant='h6'>{msg}</Typography>

        {searchContext.searchResult ?
          <InfiniteScroll
            scrollableTarget='parentdiv'
            dataLength={currentlyLoaded}
            next={searchContext.searchNextPage}
            hasMore={currentlyLoaded < (searchContext?.searchResult["@odata.count"] ?? 0)}
            loader={<Typography variant='body2'>Laden...</Typography>}
            style={{ overflow: 'hidden' }}
          >
            {searchContext.searchResult.value?.map((i, index) =>
              <SearchResultItemCard key={index} searchItem={i} />
            )}
          </InfiniteScroll>
          : null}
      </Grid>
    </Grid>
  )
}

