import React, { useEffect, FunctionComponent } from "react"
import { useSnackbar } from "notistack"
import { AppFrameworkProvider } from "../contexts/appframework.context"
import Typography from "@material-ui/core/Typography"
import { KcwMetadataDto, Datering } from '../dto/KcwMetadataDto';
import { Breadcrumbs, ButtonGroup } from "@material-ui/core";
import '../styles/kcw.scss';
import { GetTopicstoreContext, LoadTopicResult } from '../contexts/topicstore.context';
import InfiniteScroll from "react-infinite-scroll-component"
import { GetUserContext } from "../contexts/user.context"
import { useGlobalStyles } from "../styles"
import { BrondocumentItem, BrondocumentProps } from "./BrondocumentItem";
import { GetShoppingContext } from "../contexts/shopping.context";
import { Link } from "@reach/router";
import { SaveContentItem } from './SaveContentItem';

type DateringItemProps = {
  value?: Datering;
  label: string;
}

export const DateringItem: FunctionComponent<DateringItemProps> = (props) => {
  const classes = useGlobalStyles();
  if (!props.value) return null;
  // TODO: Display various types of dates
  return <Typography component="span" className={classes.metaItem} variant="caption">{props.label}{props.value.dateStart}</Typography>
}

type KcwPageProps = {
  path?: string;
  page?: string;
}

export const KcwPage: FunctionComponent<KcwPageProps> = (props) => {
  const classes = useGlobalStyles();
  const snackbar = useSnackbar();
  const topicstoreContext = GetTopicstoreContext();
  const userContext = GetUserContext();
  const shoppingContext = GetShoppingContext();
  const [content, setContent] = React.useState<string>();
  const [title, setTitle] = React.useState<string>();
  const [metadata, setMetadata] = React.useState<KcwMetadataDto>();
  const [brondocumenten, setBrondocumenten] = React.useState<Array<BrondocumentProps>>(new Array<BrondocumentProps>());
  const [lineItems, setLineItems] = React.useState<Array<BrondocumentProps>>(new Array<BrondocumentProps>());
  const [itemCount, setItemCount] = React.useState<number>(0);
  const [loadTopicResult, setloadTopicResult] = React.useState<LoadTopicResult>();
  const pageSize = 5;

  useEffect(() => {
    if (!title) return;
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (!userContext.apiToken) return;
    if (!props.page) return;

    topicstoreContext.load(props.page, pageSize)
      .then(result => setloadTopicResult(result))
      .catch(err => snackbar.enqueueSnackbar(err.message, { variant: 'error' }));

    return (() => { });
    // eslint-disable-next-line 
  }, [userContext.apiToken, props.page]);

  useEffect(() => {
    if (!loadTopicResult) return;
    setTitle(loadTopicResult?.metadata?.Titel);
    setContent(loadTopicResult?.content);
    setMetadata(loadTopicResult?.metadata);
    setBrondocumenten(() => loadTopicResult?.brondocumenten ?? []);
    nextPage();
    // eslint-disable-next-line 
  }, [loadTopicResult]);

  useEffect(() => {
    let counter = 0;
    while (counter < brondocumenten.length && brondocumenten[counter].content) {
      counter++;
    }
    setItemCount(counter);
  }, [brondocumenten]);

  useEffect(() => {
    setLineItems(brondocumenten.slice(0, itemCount));
  }, [itemCount, brondocumenten]);

  const nextPage = () => {
    if (!loadTopicResult) return;

    topicstoreContext.loadMore(loadTopicResult, pageSize)
      .then(() => setItemCount(ic => ic + pageSize))
      .catch(err => snackbar.enqueueSnackbar(err.message, { variant: 'error' }));
  }

  if (!content) return <AppFrameworkProvider />

  return (
    <AppFrameworkProvider>
      <div className={classes.title}>
        <Typography variant='h5'>
          {props.page && title ?
            <ButtonGroup className={classes.metaItem}>
              {shoppingContext.buttonFor(props.page, title)}
            </ButtonGroup>
            : null}

          {title}
        </Typography>

        {metadata?.Parents ?
          <Breadcrumbs separator={metadata?.topictype === 'Locatie' ? '/' : '|'} maxItems={3}>
            {metadata.Parents.map((i, index) =>
              <Link key={index} to={'/topics/' + i.Key + '.html'}>
                {i.Title}
              </Link>
            )}
          </Breadcrumbs>
          : null}

        <Typography variant="subtitle1">
          {metadata?.Auteurs?.length ? <span>Auteurs: {metadata.Auteurs.map(a => a.Naam).join(', ')}</span> : null}
        </Typography>

        <Typography paragraph>
          <Typography component="span" className={classes.metaItem} variant="caption">Bron: KCW</Typography>
          <DateringItem label='Datering archiefstuk: ' value={metadata?.Dateringarchiefstuk}></DateringItem>
          <DateringItem label='Datum advies: ' value={metadata?.Datumadvies} />
          <DateringItem label='Datum beschouwinsgperiode: ' value={metadata?.Datumbeschouwingsperiode} />
          <DateringItem label='Datum besluitvorming: ' value={metadata?.Datumbesluitvorming} />
          <DateringItem label='Datum interpretatie: ' value={metadata?.Datuminterpretatie} />
          <DateringItem label='Datum literatuur: ' value={metadata?.Datumliteratuur} />
          <DateringItem label='Datum thema: ' value={metadata?.Datumthema} />
          <DateringItem label='Datum waardestelling: ' value={metadata?.Datumwaardestelling} />
          {metadata?.Literatuurcode ? <Typography component="span" className={classes.metaItem} variant="caption">Literatuurcode: {metadata?.Literatuurcode}</Typography> : null}
          {metadata?.Locatienummer ? <Typography component="span" className={classes.metaItem} variant="caption">Locatienummer: {metadata?.Locatienummer}</Typography> : null}
          {metadata?.Locatiesoort ? <Typography component="span" className={classes.metaItem} variant="caption">Locatiesoort: {metadata?.Locatiesoort}</Typography> : null}
          {metadata?.Oorspronkelijketechniek ? <Typography component="span" className={classes.metaItem} variant="caption">Oorspronkelijke techniek: {metadata?.Oorspronkelijketechniek}</Typography> : null}
          {metadata?.wijzigingsdatum ? <Typography component="span" className={classes.metaItem} variant="caption">Wijzigingsdatum: {metadata?.wijzigingsdatum}</Typography> : null}
        </Typography>

      </div>

      <SaveContentItem content={content} />

      <InfiniteScroll
        dataLength={lineItems.length}
        next={nextPage}
        hasMore={itemCount < (lineItems.length)}
        loader={<Typography variant='body2'>Laden...</Typography>}
      >
        {lineItems.map((bd, index) =>
          <BrondocumentItem key={index} title={bd.title} href={bd.href} content={bd.content} />
        )}
      </InfiniteScroll>
    </AppFrameworkProvider >
  )
}
