import { FunctionComponent, useEffect, useState } from "react";
import { Grid, Typography, Button, makeStyles, Theme, createStyles } from "@material-ui/core";
import React from "react";
import { GetUserContext, AccessRights } from "../contexts/user.context";
import { useSnackbar } from "notistack";
import { GetFetchContext, Scope } from "../contexts/fetch.context";
import { appConfig } from "../constants";
import { GetBusyContext } from "../contexts/busy.context";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      marginLeft: theme.spacing(2),
    },
    formitem: {
      margin: theme.spacing(2)
    },
    field: {
      width: '100%',
    }
  })
);

type ReindexProps = {

}

export const ReindexForm: FunctionComponent<ReindexProps> = (props) => {
  const pdfIndexer = appConfig.PdfIndexerName

  const busyContext = GetBusyContext();
  const fetchContext = GetFetchContext();
  const userContext = GetUserContext();
  const snackbar = useSnackbar();
  const classes = useStyles();
  const [lastCompletedDate, setLastCompletedDate] = useState<Date>();
  const [isSuperUser, setIsSuperUser] = useState<boolean>(false);

  useEffect(() => {
    setIsSuperUser(userContext.hasAccessRights(AccessRights.SuperUser));
  }, [userContext, setIsSuperUser]);

  const startIndexering = () => {
    if (!isSuperUser) return;

    const url: string = appConfig.ApiBaseUri + '/indexing/' + pdfIndexer;
    busyContext.push();
    fetchContext.post(url, Scope.Admin, {})
      .then(_ => snackbar.enqueueSnackbar('Indexeren gestart', { variant: 'info' }))
      .catch((err: Error) => snackbar.enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => busyContext.pop());
  }

  useEffect(() => {
    if (!isSuperUser) return;

    const url: string = appConfig.ApiBaseUri + '/indexing/' + pdfIndexer;
    busyContext.push();
    fetchContext.get(url, Scope.Admin)
      .then(response => response.json())
      .then((date: Date) => setLastCompletedDate(date))
      .catch((err: Error) => snackbar.enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => busyContext.pop());
    // eslint-disable-next-line
  }, [props, isSuperUser]);

  return <Grid container direction="column" className={classes.form}>
    <Grid item className={classes.formitem}>
      <Typography variant='h1'>
        Indexering starten
            </Typography>
    </Grid>
    <Grid item className={classes.formitem}>
      <Typography variant='body1'>
        Laatste indexering: {lastCompletedDate}
      </Typography>
    </Grid>
    <Grid item className={classes.formitem}>
      <Button type="submit" variant="contained" color="primary" onClick={() => startIndexering()}>Start indexering</Button>
    </Grid>
  </Grid>
}