import React, { FunctionComponent } from "react";
import { useSnackbar } from "notistack"
import { AppFrameworkProvider } from '../contexts/appframework.context';
import { ShoppingCartItem } from '../dto/ShoppingCartItem';
import { GetShoppingContext } from "../contexts/shopping.context";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { IconButton, ListItemAvatar, makeStyles, ButtonGroup, Button, Dialog, DialogTitle, DialogActions, DialogContentText, DialogContent } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { navigate } from "@reach/router";
import { GetBusyContext } from "../contexts/busy.context";
import { appConfig } from "../constants";

const useStyles = makeStyles((theme) => ({
  listItem: {
  },
  listItemAvatar: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 'unset'
  },
  iconButton: {
  },
  listItemText: {
  },
  actionButtonBar: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1)
  }
}));

type ShopProps = {
  path: string;
}

type ShopItemProps = {
  item: ShoppingCartItem;
}

const ShopItem: FunctionComponent<ShopItemProps> = (props) => {
  const classes = useStyles();
  const shoppingContext = GetShoppingContext();

  const deleteItem = (item: ShoppingCartItem) => {
    shoppingContext.removeFromShoppingCart(item.item);
  }

  return (
    <ListItem alignItems="flex-start" className={classes.listItem}>
      <ListItemAvatar className={classes.listItemAvatar}>
        <IconButton size='small' aria-label="delete" onClick={() => deleteItem(props.item)} className={classes.iconButton}>
          <DeleteIcon />
        </IconButton>
      </ListItemAvatar>
      <ListItemText className={classes.listItemText}
        primary={props.item.title}
      />
    </ListItem>
  );
}

export const Shop: FunctionComponent<ShopProps> = () => {
  const classes = useStyles();
  const shoppingContext = GetShoppingContext();
  const busyContext = GetBusyContext();
  const [confirming, setConfirming] = React.useState<boolean>(false);
  const [sending, setSending] = React.useState<boolean>(false);
  const snackbar = useSnackbar();

  const handleClearShoppingCart = () => {
    shoppingContext.clearShoppingCart()
      .then(() => navigate(appConfig.PublicUri + '/'))
      .catch(err => snackbar.enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => setConfirming(false));
  }

  const handleVersturen = () => {
    shoppingContext.sendShoppingCart()
      .then((success) => {
        if (success) {
          const msg: string = 'Het zip-bestand wordt via email verstuurd. Controleer de spam folder in je mail applicatie. Het kan zijn dat het zip-bestand daar terechtkomt.';
          shoppingContext.clearShoppingCart()
            .then(() => {
              navigate(appConfig.PublicUri + '/');
              snackbar.enqueueSnackbar(msg, { variant: 'success' });
            })
            .catch((err) => snackbar.enqueueSnackbar(err.message, { variant: 'error' }))
            .finally(() => {
              setSending(false);
              busyContext.pop();
            });
        } else {
          snackbar.enqueueSnackbar('Versturen van opdracht mislukt', { variant: 'error' });
          busyContext.pop();
        }
      })
      .catch((err) => snackbar.enqueueSnackbar(err.message, { variant: 'error' }))
  }

  return (
    <AppFrameworkProvider>
      <ButtonGroup disabled={shoppingContext.shoppingCart.items.length === 0} variant="text" className={classes.actionButtonBar}>
        <Button onClick={() => setSending(true)}>Versturen</Button>
        <Button onClick={() => setConfirming(true)}>Alles verwijderen</Button>
      </ButtonGroup>
      <List>
        {shoppingContext.shoppingCart.items.map((item, index) => <ShopItem key={index} item={item} />)}
      </List>
      <Dialog open={confirming}>
        <DialogTitle>Alles verwijderen?</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>Weet u zeker dat u alle items uit het winkelwagentje wilt verwijderen?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus color="primary" onClick={() => setConfirming(false)}>Annuleren</Button>
          <Button variant="contained" onClick={() => handleClearShoppingCart()}>OK</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={sending}>
        <DialogTitle>Winkelwagen versturen?</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>Weet u zeker dat u alle ({shoppingContext.shoppingCart.items.length}) items uit het winkelwagentje wilt ontvangen via email?</DialogContentText>
          <DialogContentText>U ontvangt een email met een link naar een zip-bestand dat u kunt downloaden. De link is beperkte tijd geldig.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus color="primary" onClick={() => setSending(false)}>Annuleren</Button>
          <Button variant="contained" onClick={() => handleVersturen()}>OK</Button>
        </DialogActions>
      </Dialog>
    </AppFrameworkProvider>
  );
}