import React, { FunctionComponent, useEffect } from 'react';
import { AppFrameworkProvider } from './contexts/appframework.context';
import { GetUserContext } from './contexts/user.context';
import { Container, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import DOMPurify from 'dompurify';
import { GetFetchContext, Scope } from './contexts/fetch.context';
import { SaveContentItem } from './pages/SaveContentItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    login: {
      marginTop: theme.spacing(4)
    },
    welcome: {
      margin: theme.spacing(4)
    }
  }),
);

type AppProps = {
  path?: string;
}

export const App: FunctionComponent<AppProps> = (props) => {
  const classes = useStyles();
  const userContext = GetUserContext();
  const fetchContext = GetFetchContext();
  const snackbar = useSnackbar();
  const [welcome, setWelcome] = React.useState<string>();

  useEffect(() => {
    if (props?.path?.toLowerCase() === '/logout') {
      userContext.logoutForced();
    } else {
      if (userContext.authenticationState === 'Authenticated' && userContext?.loggedIn?.start) {

        fetchContext.get(userContext?.loggedIn?.start, Scope.User)
          .then(response => response.text())
          .then(data => {
            var parser = new DOMParser();
            var document = parser.parseFromString(data, "text/html");
            setWelcome(DOMPurify.sanitize(document.documentElement.outerHTML));
          })
          .catch(err => snackbar.enqueueSnackbar(err.message, { variant: 'error' }))
      }
    }
    // eslint-disable-next-line
  }, [userContext.authenticationState, userContext?.loggedIn?.start])

  if (userContext.authenticationState === 'Authenticated') {
    return (
      <AppFrameworkProvider>
        {welcome ?
          <div className={classes.welcome}>
            <SaveContentItem content={welcome} />
          </div>
          : null}

      </AppFrameworkProvider>
    )
  } else {
    return (
      <AppFrameworkProvider>
        <Container>
          <Typography className={classes.login} variant="body1">Login of vraag een account aan door een e-mail te sturen naar <a href="mailto:info@waardestelling.com">info@waardestelling.com</a>.</Typography>
        </Container>
      </AppFrameworkProvider>
    )
  }
}