import React, { FunctionComponent, createContext, useContext, useEffect } from 'react';
import { AuthenticationState, MsalAuthProvider, AccessTokenResponse } from 'react-aad-msal';
import { Account } from 'msal';
import { aadCallbackInfo } from '../dto/aadCallbackInfo';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { LoggedInDto } from '../dto/LoggedInDto';
import { FetchProvider } from './fetch.context';
import { apiScope, appConfig } from '../constants';

export enum AccessRights {
  Kcw,
  Pdf,
  SuperUser,
  Admin
}

export type UserContextType = {
  account: Account | null;
  authenticationState: AuthenticationState;
  tokenExpires?: Date;
  idToken: string;
  apiToken: string;
  loggedIn?: LoggedInDto;

  login: () => void;
  logout: () => void;
  logoutForced: () => void;
  refreshToken: () => Promise<string>;
  setLoggedIn: (newUser: LoggedInDto) => void;
  hasAccessRights: (accessRights: AccessRights) => boolean;
}

const UserContext = createContext<UserContextType>(
  {
    account: null,
    authenticationState: AuthenticationState.Unauthenticated,
    idToken: '',
    apiToken: '',

    login: () => { throw new Error(); },
    logout: () => { throw new Error(); },
    logoutForced: () => { throw new Error(); },
    refreshToken: () => { throw new Error(); },
    setLoggedIn: (newUser: LoggedInDto) => { throw new Error(); },
    hasAccessRights: (accessRights: AccessRights) => { throw new Error(); }
  }
);

type UserProviderProps = {
  aadCallbackInfo: aadCallbackInfo;
  authProvider: MsalAuthProvider;
}

export const UserProvider: FunctionComponent<UserProviderProps> = (props) => {
  const snackbar = useSnackbar();
  const [apiToken, setApiToken] = React.useState<string>('');
  const [apiTokenExpires, setApiTokenExpires] = React.useState<Date>();
  const [loggedIn, setLoggedIn] = React.useState<LoggedInDto>();
  const [authenticationState] = React.useState<AuthenticationState>(props?.aadCallbackInfo.authenticationState || AuthenticationState.Unauthenticated);

  useEffect(() => {
    if (authenticationState === 'Authenticated') {
      getApiToken(apiScope)
        .then(token => setApiToken(token))
        .catch(err => snackbar.enqueueSnackbar(err.message, { variant: 'error' }));
    } else {
      setApiToken('');
    }
    // eslint-disable-next-line
  }, [authenticationState]);

  const login = () => {
    try {
      props.aadCallbackInfo.login();
    }
    catch (err) {
      snackbar.enqueueSnackbar(err.message, { variant: 'error' });
    }
  }

  const logout = () => {
    setConfirmLogoff(true);
  }

  const hasAccessRights = (accessRights: AccessRights): boolean => {
    if (!loggedIn?.userInfo) return false;
    switch (accessRights) {
      case AccessRights.Admin:
        return loggedIn.userInfo.extension_f5082336026d411daf7f0cac4b100511_Role === 'Admin';
      case AccessRights.Kcw:
        return loggedIn.userInfo.extension_f5082336026d411daf7f0cac4b100511_Role === 'Admin' ||
          loggedIn.userInfo.extension_f5082336026d411daf7f0cac4b100511_Role === 'Kcw' ||
          loggedIn.userInfo.extension_f5082336026d411daf7f0cac4b100511_Role === 'All' ||
          !loggedIn?.userInfo.extension_f5082336026d411daf7f0cac4b100511_Role;
      case AccessRights.Pdf:
        return loggedIn.userInfo.extension_f5082336026d411daf7f0cac4b100511_Role === 'Admin' ||
          loggedIn.userInfo.extension_f5082336026d411daf7f0cac4b100511_Role === 'SuperUser' ||
          loggedIn.userInfo.extension_f5082336026d411daf7f0cac4b100511_Role === 'All' ||
          loggedIn.userInfo.extension_f5082336026d411daf7f0cac4b100511_Role === 'Pdf' ||
          !loggedIn.userInfo.extension_f5082336026d411daf7f0cac4b100511_Role;
      case AccessRights.SuperUser:
        return loggedIn.userInfo.extension_f5082336026d411daf7f0cac4b100511_Role === 'Admin' ||
          loggedIn.userInfo.extension_f5082336026d411daf7f0cac4b100511_Role === 'SuperUser';
      default:
        return false;
    }
  }

  const getApiToken = (scope: string): Promise<string> => {
    var promise = new Promise<string>((resolve, reject) => {
      props.authProvider.getAccessToken({
        scopes: [
          scope
        ]
      })
        .then((tokenResponse: AccessTokenResponse) => {
          setApiTokenExpires(tokenResponse.expiresOn);
          resolve(tokenResponse.accessToken);
        })
        .catch(err => reject(err))
    });
    return promise;
  }

  const refreshToken = () => getApiToken(appConfig.ApiScope);

  const [confirmLogoff, setConfirmLogoff] = React.useState<boolean>(false);

  const handleCancel = () => setConfirmLogoff(false);

  const handleOk = () => {
    try {
      props.aadCallbackInfo.logout();
    }
    catch (err) {
      snackbar.enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setConfirmLogoff(false);
    }
  };

  
  const value: UserContextType = {
    account: props?.aadCallbackInfo.accountInfo?.account,
    authenticationState: authenticationState,
    idToken: props?.aadCallbackInfo.accountInfo?.jwtIdToken,
    apiToken: apiToken,
    loggedIn: loggedIn,
    tokenExpires: apiTokenExpires,

    login: login,
    refreshToken: refreshToken,
    logout: logout,
    logoutForced: handleOk,
    hasAccessRights: hasAccessRights,
    setLoggedIn: setLoggedIn
  }

  return (
    <UserContext.Provider value={value}>
      <FetchProvider token={apiToken}>
        {props.children}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          aria-labelledby="confirmation-dialog-title"
          open={confirmLogoff}
        >
          <DialogTitle id="confirmation-dialog-title">Uitloggen</DialogTitle>
          <DialogContent dividers>
            <Typography variant="body1" align="left">Bedankt voor je bezoek aan het studieproject 'Cultuurhistorische informatie en BIM' van het Atelier Rijksbouwmeester.</Typography>
            <Typography variant="body1" align="left">Voor vragen en opmerkingen: stuur een e-mail naar <a href="mailto:info@waardestelling.com">info@waardestelling.com</a>. Tot een volgende keer.</Typography>
            <Typography variant="caption">
              ObjectId: {props.aadCallbackInfo.accountInfo?.account.idTokenClaims.oid}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" autoFocus color="primary" onClick={handleCancel}>Annuleren</Button>
            <Button variant="contained" onClick={handleOk}>OK</Button>
          </DialogActions>
        </Dialog>
      </FetchProvider>
    </UserContext.Provider>
  );
};

export const GetUserContext = (): UserContextType => {
  return useContext(UserContext);
};

