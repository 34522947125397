import Pagination from '@material-ui/lab/Pagination';
import React, { FunctionComponent, useEffect } from "react";
import { SearchResult } from "../dto/SearchResult";
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import SettingsBackupRestoreOutlinedIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import Image from 'material-ui-image';
import path from "path";
import { GetFullscreenContext } from "../contexts/fullscreen.context";
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import { Typography, makeStyles, Grid, ButtonGroup, Button, Tooltip, Paper, Divider } from "@material-ui/core";
import { GetShoppingContext } from '../contexts/shopping.context';
import { useGlobalStyles } from '../styles';
import { appConfig } from '../constants';
import { Link } from '@reach/router';

const useStyles = makeStyles((theme) => ({
  paginanummer: {
    marginLeft: theme.spacing(1)
  },
  pageLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    },
    '&:active': {
      textDecoration: 'underline'
    },
    '&:focus': {
      textDecoration: 'underline'
    }
  },
  buttons: {
    marginBottom: theme.spacing(2)
  },
  card: {
    width: 600,
  },
  metaItem: {
    marginRight: theme.spacing(1)
  },
  resultList: {
    margin: theme.spacing(2)
  },
  summary: {
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  highlight: {
  },
  kcwItem: {
    maxHeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:after': {
      width: '100px',
      height: '100px',
      background: 'yellow'
    }
  },
  pdfPreview: {
    maxWidth: 600,
    maxHeigh: 600
  }
}));

type SearchResultItemCardProps = {
  searchItem: SearchResult;
}

export const SearchResultItemCard: FunctionComponent<SearchResultItemCardProps> = (props) => {
  switch (props.searchItem.origin) {
    case "PDF":
      return <PdfResultItemCard searchItem={props.searchItem} />
    case "KCW":
      return <KcwResultItemCard searchItem={props.searchItem} />
    default:
      return null;
  }
}

export const PdfResultItemCard: FunctionComponent<SearchResultItemCardProps> = (props) => {
  const shoppingContext = GetShoppingContext();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const fullscreenContext = GetFullscreenContext();
  const thisPagenumber = props.searchItem.document.pdfmetadata?.pageNr ?? 1;
  const prefixprefix = appConfig.BlobContainerUri;
  const pdfpageprefix = prefixprefix + appConfig.PdfPagesLocation + props.searchItem.document.pdfmetadata?.rowKey + '/';
  const parentfile = prefixprefix + appConfig.PdfsLocation + props.searchItem.document.pdfmetadata?.rowKey + '.pdf';
  const previewsprefix = prefixprefix + appConfig.PreviewsLocation + props.searchItem.document.pdfmetadata?.rowKey + '/';
  const imagesprefix = prefixprefix + appConfig.ImagesLocation + props.searchItem.document.pdfmetadata?.rowKey + '/';
  const highresimagesprefix = prefixprefix + appConfig.HighResImagesLocation + props.searchItem.document.pdfmetadata?.rowKey + '/';
  const highrespreviewsprefix = prefixprefix + appConfig.HighResPreviewsLocation + props.searchItem.document.pdfmetadata?.rowKey + '/';
  const titel = props.searchItem.document.titel ?? parentfile;

  const [imageNumber, setImageNumber] = React.useState<number>(1);
  const [defaultPage] = React.useState<number>(+thisPagenumber ?? 0);
  const [paginaNummer, setPaginaNummer] = React.useState<number>(defaultPage);

  const handleImageNumber = (event: React.ChangeEvent<unknown>, value: number) => {
    setImageNumber(value);
  };

  const handlePreviewPage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPaginaNummer(value);
  };

  const handleResetPreviewPage = () => {
    setPaginaNummer(defaultPage);
  };

  const handleImageClick = (src: string) => {
    fullscreenContext.setImage({
      src: src,
      alt: ''
    });
  }

  useEffect(() => {
    setImageNumber(1);
  }, [paginaNummer]);

  if (!props.searchItem.document.pdfmetadata) return null;

  return (
    <>

      {parentfile ?
        <Typography variant="h5">
          <ButtonGroup className={globalClasses.buyButton}>
            <Tooltip title="Gehele bestand">
              {shoppingContext.buttonFor(parentfile, titel)}
            </Tooltip>
          </ButtonGroup>
          <a className={classes.pageLink} href={parentfile} target="_blank" rel="noopener noreferrer">
            {titel}
          </a>
        </Typography>
        :
        <Typography variant="h5">
          {titel}
        </Typography>
      }

      {
        props.searchItem.document.auteurs.length ?
          <Typography variant="caption">Auteurs: {props.searchItem.document.auteurs.map(a => a.naam).join(', ')}</Typography>
          : null
      }
      <Typography paragraph>
        <Typography component="span" className={classes.metaItem} variant="caption">Pagina {thisPagenumber}</Typography>
        {props.searchItem.origin ? <Typography component="span" className={classes.metaItem} variant="caption">Bron: {props.searchItem.origin}</Typography> : null}
        {props.searchItem.document.pdfmetadata.jaar ? <Typography component="span" className={classes.metaItem} variant="caption">Jaar: {props.searchItem.document.pdfmetadata.jaar}</Typography> : null}
        {props.searchItem.document.pdfmetadata.adres ? <Typography component="span" className={classes.metaItem} variant="caption">Adres: {props.searchItem.document.pdfmetadata.adres}</Typography> : null}
        {props.searchItem.document.pdfmetadata.plaats ? <Typography component="span" className={classes.metaItem} variant="caption">Plaats: {props.searchItem.document.pdfmetadata.plaats}</Typography> : null}
        {props.searchItem.document.pdfmetadata.nrrgd ? <Typography component="span" className={classes.metaItem} variant="caption">Nr Rgd: {props.searchItem.document.pdfmetadata.nrrgd}</Typography> : null}
        {props.searchItem.document.pdfmetadata.nrdvd ? <Typography component="span" className={classes.metaItem} variant="caption">Nr DVD: {props.searchItem.document.pdfmetadata.nrdvd}</Typography> : null}
      </Typography>

      {
        props.searchItem["@search.highlights"]?.content?.map((sh, index) =>
          <Typography key={index} variant="body2" className={classes.summary} paragraph dangerouslySetInnerHTML={{ __html: sh }} />
        )
      }

      {
        props.searchItem.document.pdfmetadata.pages ?
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container>
                <Grid item>
                  <ButtonGroup className={globalClasses.buyButton}>
                    {props.searchItem.document.pdfmetadata?.pages[paginaNummer - 1]?.preview ?
                      <Tooltip title={'Pagina ' + paginaNummer}>
                        {shoppingContext.buttonFor(pdfpageprefix + props.searchItem.document.pdfmetadata.pages[paginaNummer - 1].file,
                          'Pagina ' + paginaNummer + ' van ' + titel)}
                      </Tooltip> : null}
                    {props.searchItem.document.pdfmetadata?.pages[paginaNummer - 1]?.preview ?
                      <Button size="small" href={pdfpageprefix + props.searchItem.document.pdfmetadata.pages[paginaNummer - 1].file} aria-label={'Pagina ' + paginaNummer}>
                        <Tooltip title={'Download pagina ' + paginaNummer}>
                          <PictureAsPdfOutlinedIcon />
                        </Tooltip>
                      </Button>
                      : null}
                    {props.searchItem.document.pdfmetadata?.pages[paginaNummer - 1]?.preview ?
                      <Button size="small" href={highrespreviewsprefix + props.searchItem.document.pdfmetadata?.pages[paginaNummer - 1]?.preview} aria-label={'Afbeelding pagina ' + paginaNummer}>
                        <Tooltip title={'Afbeelding pagina ' + paginaNummer}>
                          <SystemUpdateAltOutlinedIcon />
                        </Tooltip>
                      </Button>
                      : null}
                    <Button disabled={paginaNummer === defaultPage} size="small" onClick={handleResetPreviewPage}>
                      <Tooltip title={'Ga naar pagina ' + defaultPage}>
                        <SettingsBackupRestoreOutlinedIcon />
                      </Tooltip>
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Grid item>
                  <Pagination
                    count={props.searchItem.document.pdfmetadata.pages.length}
                    size="small"
                    siblingCount={3}
                    boundaryCount={1}
                    onChange={handlePreviewPage}
                    page={paginaNummer}
                    defaultPage={defaultPage} />
                </Grid>
              </Grid>

              <Paper elevation={3} style={{ width: 500, height: 500 }}    >
                <Image
                  onClick={() => handleImageClick(highrespreviewsprefix + props.searchItem.document.pdfmetadata?.pages[paginaNummer - 1]?.preview)}
                  disableTransition
                  imageStyle={{ width: 'auto', height: 'auto' }}
                  src={previewsprefix + props.searchItem.document.pdfmetadata?.pages[paginaNummer - 1]?.preview} />
              </Paper>

            </Grid>
            <Grid item xs={6}>
              {props.searchItem.document.pdfmetadata?.pages[paginaNummer - 1]?.images.length > 0 ?
                <>
                  <Grid container>
                    <Grid item>
                      <ButtonGroup className={globalClasses.buyButton}>
                        <Tooltip title={'Afbeelding ' + imageNumber + ' van pagina ' + paginaNummer}>
                          {shoppingContext.buttonFor(highresimagesprefix + props.searchItem.document.pdfmetadata.pages[paginaNummer - 1].images[imageNumber - 1],
                            'Afbeelding ' + imageNumber + ' van pagina ' + paginaNummer + ' van ' + titel)}
                        </Tooltip>
                        <Button size="small" disabled={!props.searchItem.document.pdfmetadata.pages[paginaNummer - 1].images[imageNumber - 1]}
                          href={highresimagesprefix + props.searchItem.document.pdfmetadata.pages[paginaNummer - 1].images[imageNumber - 1]}>
                          <Tooltip title="Download hoge kwaliteit afbeelding">
                            <SystemUpdateAltOutlinedIcon />
                          </Tooltip>
                        </Button>
                        {/* <Button
  size="small"
  disabled={!props.searchItem.document.pdfmetadata.pages[paginaNummer - 1].images[imageNumber - 1]}
  onClick={() => copyToClipboard(highresimagesprefix + props.searchItem.document.pdfmetadata.pages[paginaNummer - 1].images[imageNumber - 1])}>
  <Tooltip title="Plaats afbeelding op clipboard">
  <ImageOutlinedIcon />
  </Tooltip>
  </Button> */}
                      </ButtonGroup>
                    </Grid>
                    <Grid item>
                      <Pagination
                        count={props.searchItem.document.pdfmetadata.pages[paginaNummer - 1].images.length}
                        size="small"
                        siblingCount={4}
                        boundaryCount={1}
                        page={imageNumber}
                        onChange={handleImageNumber} />
                    </Grid>
                  </Grid>
                  <Paper elevation={3} style={{ width: 500, height: 500 }}>
                    {
                      <Image
                        onClick={() => handleImageClick(highresimagesprefix + props.searchItem.document.pdfmetadata.pages[paginaNummer - 1].images[imageNumber - 1])}
                        disableTransition
                        imageStyle={{ width: 'auto', height: 'auto' }}
                        src={imagesprefix + props.searchItem.document.pdfmetadata.pages[paginaNummer - 1].images[imageNumber - 1]} />
                    }
                  </Paper>
                </>
                : null}
            </Grid>
          </Grid>
          : null
      }

      <Divider className={classes.divider} />
    </>
  )
}

export const KcwResultItemCard: FunctionComponent<SearchResultItemCardProps> = (props) => {
  const classes = useStyles();
  const dirname = path.dirname(props.searchItem.document.metadata_storage_path_decoded);
  const topic = props.searchItem.document.metadata_storage_path_decoded.substr(dirname.length + 1);
  return (
    <>
      <Typography variant="h5">
        <Link className={classes.pageLink} to={'/topics/' + topic}>{props.searchItem.document.titel ?? topic}</Link>
      </Typography>
      <Typography paragraph>
        {props.searchItem.origin ? <Typography className={classes.metaItem} variant="caption">Bron: {props.searchItem.origin}</Typography> : null}
        {props.searchItem.document?.kcwmetadata?.locatienummer ? <Typography className={classes.metaItem} variant="caption">Locatienummer: {props.searchItem.document.kcwmetadata.locatienummer}</Typography> : null}
        {props.searchItem.document?.kcwmetadata?.locatiesoort ? <Typography className={classes.metaItem} variant="caption">Locatiesoort: {props.searchItem.document.kcwmetadata.locatiesoort}</Typography> : null}
        {props.searchItem.document?.kcwmetadata?.literatuurcode ? <Typography className={classes.metaItem} variant="caption">Literatuurcode: {props.searchItem.document.kcwmetadata.literatuurcode}</Typography> : null}
        {props.searchItem.document?.kcwmetadata?.oorspronkelijketechniek ? <Typography className={classes.metaItem} variant="caption">Techniek: {props.searchItem.document.kcwmetadata.oorspronkelijketechniek}</Typography> : null}
      </Typography>
      {props.searchItem["@search.highlights"]?.content?.map((sh, index) =>
        <Typography key={index} className={classes.summary} variant="body2" paragraph dangerouslySetInnerHTML={{ __html: sh }} />
      )}
      <Divider className={classes.divider} />
    </>
  )
}