import React, { FunctionComponent, useEffect } from "react";
import { useGlobalStyles } from "../styles"
import { useNavigate } from "@reach/router";
import ReactHtmlParser from 'react-html-parser';
import { appConfig } from "../constants";

type SaveContentItemProps = {
  content: string;
}

export const SaveContentItem: FunctionComponent<SaveContentItemProps> = (props) => {
  const contentRef = React.createRef<HTMLDivElement>();
  const classes = useGlobalStyles();
  const navigate = useNavigate();

  const openImage = (url: string) => {
    window.open(url, '_blank');
  }

  const navigateTopicLink = (event: MouseEvent) => {
    event.preventDefault();
    const thisHost = new URL(appConfig.RedirectUri);
    const href = (event.target as HTMLAnchorElement)?.href;
    try {
      const targetUrl = new URL(href);
      if (targetUrl.hostname !== thisHost.hostname) {
        window.location.href = href
      } else {
        navigate(href);
      }
    } catch (err) {
      console.log(`${href}: ${err}`);
    }
  }

  const loadImageReferences = (event: any) => {
    const img = event.path[0] as HTMLImageElement;
    if (!img) return;
    const url = img.dataset.highres;
    if (!url) return;

    img.className = classes.withhighres;
    img.onclick = () => openImage(url);
  }

  useEffect(() => {
    var images = contentRef.current?.getElementsByTagName('img');
    if (images) {
      for (let i = 0; i < images.length; i++) {
        const img = images[i];
        console.log(img.src);
        const parent = img?.parentElement;
        if (parent?.className !== 'navigatietekening') {
          img.onload = loadImageReferences;
        }
      }
    }
    var topics = contentRef.current?.getElementsByTagName('a');
    if (topics) {
      for (let i = 0; i < topics.length; i++) {
        const topic = topics[i];
        if (topic.href.indexOf('/topics/') >= 0) {
          // Quick hack, sorry
          topic.onclick = navigateTopicLink;
        }
      }
    }
    var areas = contentRef.current?.getElementsByTagName('area');
    if (areas) {
      for (let i = 0; i < areas.length; i++) {
        const area = areas[i];
        if (area.href.indexOf('/topics/') >= 0) {
          // Quick hack, sorry
          area.onclick = navigateTopicLink;
        }
      }
    }
    // eslint-disable-next-line
  }, [props.content]);

  return (
    <div ref={contentRef}>
      {ReactHtmlParser(props.content)}
    </div>
  );
}