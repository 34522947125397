import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import React, { FunctionComponent, useEffect } from "react";
import Toolbar from '@material-ui/core/Toolbar';
import { SearchBox } from './searchBox';
import { makeStyles, Theme, createStyles, Tooltip, Typography, Badge } from '@material-ui/core';
import { GetUserContext, AccessRights } from '../contexts/user.context';
import { AuthenticationState } from 'react-aad-msal';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import { navigate } from '@reach/router';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { GetShoppingContext } from '../contexts/shopping.context';
import { appConfig } from '../constants';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
    },
    menuButton: {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
      }
    },
    avatar: {
      cursor: 'pointer',
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.background.default,
        backgroundColor: theme.palette.primary.main,
      }
    }
  }),
);

export type NavbarState = {
  isError: boolean;
}

export type NavbarProps = {
}

export const Navbar: FunctionComponent<NavbarProps> = () => {
  const classes = useStyles();
  const userContext = GetUserContext();
  const shoppingContext = GetShoppingContext();
  const snackbar = useSnackbar();
  const [userName, setUserName] = React.useState<string>('');
  const [initials, setInitials] = React.useState<string>('');
  const [isSuperUser, setIsSuperUser] = React.useState<boolean>(false);

  useEffect(() => {
    setIsSuperUser(userContext.hasAccessRights(AccessRights.SuperUser));
    setUserName(userContext.loggedIn?.userInfo.displayName ?? '');
    // eslint-disable-next-line
  }, [userContext.loggedIn]);

  useEffect(() => {
    if (!userName) {
      setInitials('');
    } else {
      try {
        let value = '';
        const items = userName.split(' ');
        const max = Math.min(items.length, 2);
        for (var i = 0; i < max; i++) {
          if (items[i].length > 0) value = value + items[i][0] ?? '';
        }
        setInitials(value);
      } catch (err) {  // Let's be prepared for names that cause strange effects
        snackbar.enqueueSnackbar(err.message, { variant: 'error'});
      }
    }
  // eslint-disable-next-line   
  }, [userName]);

  const login = () => {
    userContext.login();
  }

  const logout = () => {
    userContext.logout();
  }

  return (
    <AppBar position="fixed">
      <Toolbar className={classes.toolbar}>
        <Tooltip title={<Typography>Home</Typography>}>
          <IconButton edge='start' className={classes.menuButton} onClick={() => navigate('/')}>
            <HomeOutlinedIcon />
          </IconButton>
        </Tooltip>

        <SearchBox />

        {isSuperUser ?
          <IconButton className={classes.menuButton} onClick={() => navigate('/administrator')}>
            <DashboardOutlinedIcon />
          </IconButton>
          :
          null}

        <IconButton disabled={shoppingContext.shoppingCart.items.length === 0} className={classes.menuButton} onClick={() => navigate(appConfig.PublicUri + '/shop')}>
          <Badge badgeContent={shoppingContext.shoppingCart.items.length} color="secondary" showZero={false}>
            <ShoppingCartOutlinedIcon />
          </Badge>
        </IconButton>

        {userContext.authenticationState === AuthenticationState.Authenticated ?
          <Tooltip title={<Typography>{userName}</Typography>}>
            <IconButton edge='end' onClick={logout} className={classes.menuButton}>
              {initials}
            </IconButton>
          </Tooltip>
          :
          <IconButton edge='end' onClick={login} className={classes.menuButton}>
            <AccountCircleIcon />
          </IconButton>
        }
      </Toolbar >
    </AppBar >
  );
}
