import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useGlobalStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: 9999
        },
        root: {
            display: 'flex',
        },
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        menuButton: {
        },
        hide: {
            display: 'none',
        },
        withhighres: {
          borderWidth: 2,
          padding: 1,
          borderColor: theme.palette.primary.main,
          borderStyle: 'solid',
          cursor: 'pointer',
          '&::after': {
            content: 'Highres available'
          }
        },
        title: {
          marginTop: theme.spacing(3),
          marginLeft: 320,
          marginBottom: theme.spacing(1)
        },
        metaItem: {
          marginRight: theme.spacing(1)
        },
        buyButton: {
          marginRight: theme.spacing(1)
        }
   }),
);
