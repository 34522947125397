import React from "react";

export type PageNotFoundProps = {
  default: boolean;
}

export const PageNotFound: React.FunctionComponent<PageNotFoundProps> = (props) => {
  return (
    <h1>Sorry, even verder zoeken</h1>
  );
}
