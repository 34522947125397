import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import React, { FunctionComponent, useEffect } from "react";
import { Typography, Grid, List, ListItem, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { GetUserContext } from '../contexts/user.context';
import { TocItem } from '../dto/tocItem';
import { GetFetchContext, Scope } from '../contexts/fetch.context';
import { appConfig } from '../constants';
import { Link } from '@reach/router';
import { GetBusyContext } from '../contexts/busy.context';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  tocTitle: {
    margin: theme.spacing(1)
  },
  card: {
    width: 400,
    margin: theme.spacing(1)
  },
  cardTitle: {
    height: 60
  },
  cardContent: {
    height: 200,
    overflowY: 'scroll',
    borderTopColor: theme.palette.primary.main,
    borderTopStyle: 'solid',
    borderTopWidth: 1
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  list: {
    margin: 0,
    padding: 0,
  },
  listItem: {
    margin: 0,
    padding: 0
  }
}));

type TocViewProps = {
  parent: string;
}

export const TocView: FunctionComponent<TocViewProps> = (props: TocViewProps) => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const fetchContext = GetFetchContext();
  const userContext = GetUserContext();
  const busyContext = GetBusyContext();

  const [title, setTitle] = React.useState<string>();
  const [toc, setToc] = React.useState<TocItem>();
  const [children, setChildren] = React.useState<Array<TocItem>>();

  useEffect(() => {
    if (!props.parent) return;
    if (!userContext.loggedIn) return;

    const uri: string = appConfig.ApiBaseUri + '/toc/' + props.parent;

    busyContext.push();
    fetchContext.get(uri, Scope.KCW)
      .then(response => response.json())
      .then((tocItem: TocItem) => {
        setTitle(tocItem.title);
        setToc(tocItem);
        setChildren(tocItem.children);
      })
      .catch(err => snackbar.enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => busyContext.pop());

    // eslint-disable-next-line
  }, [props.parent, userContext.loggedIn]);

  return (
    <Grid container>
      <Grid item xs={2}>
        <List>
          {toc?.children?.map((c, index) =>
            c.itemType === 'Chapter' ?
              <ListItem button key={index}><Link to={'/chapter/' + c.rowKey}>{c.title}</Link></ListItem>
              :
              <ListItem button key={index}><Link to={'/topics/' + c.rowKey + '.html'}>{c.title}</Link></ListItem>
          )}
        </List>
      </Grid>
      <Grid item xs={10}>
        {title !== '(root)' ?
          <Typography variant='h6' className={classes.tocTitle}>{title}</Typography>
          : null}
        <Grid container>
          {children?.map((c, index) =>
            <TopicTocView key={index} item={c} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

type TopicTocViewProps = {
  item: TocItem;
}
export const TopicTocView: FunctionComponent<TopicTocViewProps> = (props: TopicTocViewProps) => {
  const classes = useStyles();
  const itemLink = props.item.itemType === 'Chapter' ? '/chapter/' + props.item.rowKey : '/topics/' + props.item.rowKey + '.html';

  return (
    <Grid item>
      <Card className={classes.card}>
        <CardHeader title={<Link to={itemLink}>{props.item.title}</Link>} className={classes.cardTitle}>
        </CardHeader>
        <CardContent className={classes.cardContent}>
          <List className={classes.list}>
            {props.item.children.map((c, index) => {
              const cLink = c.itemType === 'Chapter' ? '/chapter/' + c.rowKey : '/topics/' + c.rowKey + '.html';
              return (<ListItem className={classes.listItem} button key={index}><Link to={cLink}>{c.title}</Link></ListItem>);
            })}
          </List>
        </CardContent>
      </Card>
    </Grid>
  );
}