import { BlobServiceClient } from "@azure/storage-blob";
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { TextField, Grid, makeStyles, Theme, createStyles, Button, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { GetBusyContext } from "../contexts/busy.context";
import { PdfMetaInformation, Auteur } from "../dto/PdfMetaInformation";
import path from 'path';
import { Scope, GetFetchContext } from "../contexts/fetch.context";
import { appConfig } from "../constants";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      marginLeft: theme.spacing(2),
    },
    formitem: {
      margin: theme.spacing(2)
    },
    field: {
      width: '100%',
    }
  })
);

type UploadFileFormProps = {
}

type Inputs = {
  updatemetadata: boolean;
  files: FileList;
  titel: string;
  auteur: string;
  plaats: string;
  adres: string;
  jaar: string;
  nrDVD: string;
  nrRgd: string;
}

export const UploadFileForm: FunctionComponent<UploadFileFormProps> = () => {
  const busyContext = GetBusyContext();
  const fetchContext = GetFetchContext();
  const classes = useStyles();
  const snackbar = useSnackbar();
  const { register, handleSubmit, errors, reset } = useForm<Inputs>();
  const uploadAccount: string = appConfig.UploadAccountUri;
  const uploadContainer: string = appConfig.UploadContainer;
  const metaUrl: string = appConfig.ApiBaseUri + '/metadata/pdf';

  const onSubmit = (data: Inputs) => {
    const url: string = appConfig.ApiBaseUri + '/uploadtoken';

    busyContext.push();
    fetchContext.get(url, Scope.Admin)
      .then((response: any) => response.text())
      .then((sasToken: string) => {
        // Upload file(s)
        const connectUrl = uploadAccount + sasToken;
        const blobServiceClient = new BlobServiceClient(connectUrl);
        const containerClient = blobServiceClient.getContainerClient(uploadContainer);
        const promises = new Array<Promise<any>>();

        // TODO: Use FetchContext to update with alternate authorization token

        for (var i = 0; i < data.files.length; i++) {
          const file = data.files[i];

          // Send meta data
          const ext = path.extname(file.name);
          const name = ext ? file.name.replace(ext, '') : file.name;
          const meta: PdfMetaInformation = {
            adres: data.adres,
            auteurs: new Array<Auteur>(),
            jaar: data.jaar,
            nrDvd: data.nrDVD,
            nrRgd: data.nrRgd,
            objectnaam: data.titel,
            plaats: data.plaats,
            titel: data.titel
          }
          data.auteur.split(',').forEach(a => meta.auteurs.push({ Naam: a }));

          let metadataPromise: Promise<any>;
          if (data.updatemetadata) {
            // First upload the meta data, to make sure that's there when the pdf file arrives
            const metadataPostUrl = metaUrl + "/" + name;
            metadataPromise = fetchContext.post(metadataPostUrl, Scope.Admin, meta);
          } else {
            metadataPromise = Promise.resolve();
          }

          metadataPromise.then(() => {
            const blockBlobClient = containerClient.getBlockBlobClient(file.name);
            blockBlobClient.uploadBrowserData(file)
              .catch(err => snackbar.enqueueSnackbar(err.message, { variant: 'error' }))
          })
          .catch(err => snackbar.enqueueSnackbar(err.message, { variant: 'error' }));

          promises.push(metadataPromise);
        }
        busyContext.push();
        Promise.all(promises)
          .then(() => {
            snackbar.enqueueSnackbar('Bestand verzonden', { variant: 'success' });
            reset();
          })
          .finally(() => busyContext.pop());
      })
      .catch((err: Error) => snackbar.enqueueSnackbar('Foutje: ' + err.message, { variant: 'error' }))
      .finally(() => busyContext.pop());
  }

  return <form onSubmit={handleSubmit(onSubmit)} className={classes.form} >
    <Grid container direction="column">
      <Grid item className={classes.formitem}>
        <Typography variant='h1'>
          PDF uploaden
                </Typography>
        <Typography variant='h6' color='primary'>
          Let op: bestaande bestanden en metadata worden zonder waarschuwing overschreven (voorlopig)
                </Typography>
      </Grid>
      <Grid item className={classes.formitem}>
        <input type="file" name="files" ref={register} />
        {/* <TextField inputRef={register} label="File" name="file" required error={errors.file !== undefined} className={classes.field} /> */}
      </Grid>
      <Grid item className={classes.formitem}>
        <FormControlLabel control={<Checkbox inputRef={register} name="updatemetadata" />} label="Metadata bijwerken" className={classes.field} />
      </Grid>
      <Grid item className={classes.formitem}>
        <TextField inputRef={register} label="Titel" name="titel" className={classes.field} />
      </Grid>
      <Grid item className={classes.formitem}>
        <TextField inputRef={register} label="Auteurs" name="auteur" helperText="Gebruik komma's om de namen van auteurs te scheiden" error={errors.auteur !== undefined} className={classes.field} />
      </Grid>
      <Grid item className={classes.formitem}>
        <TextField inputRef={register} label="Adres" name="adres" error={errors.adres !== undefined} className={classes.field} />
      </Grid>
      <Grid item className={classes.formitem}>
        <TextField inputRef={register} label="Plaats" name="plaats" error={errors.plaats !== undefined} className={classes.field} />
      </Grid>
      <Grid item className={classes.formitem}>
        <TextField inputRef={register} label="Jaar" name="jaar" error={errors.jaar !== undefined} className={classes.field} />
      </Grid>
      <Grid item className={classes.formitem}>
        <TextField inputRef={register} label="Nr Rgd" name="nrRgd" error={errors.nrRgd !== undefined} className={classes.field} />
      </Grid>
      <Grid item className={classes.formitem}>
        <TextField inputRef={register} label="Nr DVD" name="nrDVD" error={errors.nrDVD !== undefined} className={classes.field} />
      </Grid>
      <Grid item className={classes.formitem}>
        <Button type="submit" variant="contained" color="primary">Upload</Button>
      </Grid>
    </Grid>
  </form>
}
