import React, { FunctionComponent, useEffect, useState } from 'react';
import { UploadFileForm } from './uploadFile';
import { AppFrameworkProvider } from '../contexts/appframework.context';
import { Grid, makeStyles, createStyles, List, ListItemText, ListItem, Divider } from '@material-ui/core';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import { RetryQueueForm } from './retryqueue';
import { RemovePdfForm } from './removepdf';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined';
import { ReindexForm } from './reindex'
import { StatisticsPage } from './statistics';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { Users } from './users';
import { GetUserContext, AccessRights } from '../contexts/user.context';
import { Link } from '@reach/router';

export const useStyles = makeStyles((theme) =>
    createStyles({
        leftMenu: {
            borderRight: 'solid 1px black'
        },
        button: {
            marginRight: theme.spacing(1)
        },
        menuLink: {
        },
        content: {
        },
        container: {
            display: 'flex',
            flexWrap: 'nowrap',
            margin: 0
        }
    })
);

type AdminProps = {
    path?: string;
    page?: string;
    pagenumber?: number;
    pagecount?: number;
    prefix?: string;
}

export const Admin: FunctionComponent<AdminProps> = (props) => {
    const classes = useStyles();
    const userContext = GetUserContext();
    const [payload, setPayload] = useState<any>();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isSuperUser, setIsSuperUser] = useState<boolean>(false);

    useEffect(() => {
        setIsAdmin(userContext.hasAccessRights(AccessRights.Admin));
        setIsSuperUser(userContext.hasAccessRights(AccessRights.SuperUser));
    }, [userContext, setIsAdmin, setIsSuperUser]);

    useEffect(() => {
        switch (props.page) {
            case 'upload':
                setPayload(<UploadFileForm />);
                break;
            case 'delete':
                setPayload(<RemovePdfForm />);
                break;
            case 'retryqueue':
                setPayload(<RetryQueueForm />);
                break;
            // case 'metadata':
            //     setPayload(<MetadataForm />);
            //     break;
            case 'reindex':
                setPayload(<ReindexForm />);
                break;
            case 'users':
                setPayload(<Users />);
                break;
            default:
                setPayload(<StatisticsPage pagenumber={props.pagenumber} pagecount={props.pagecount} prefix={props.prefix} />);
                break;
        }
    }, [props.page, props.pagecount, props.pagenumber, props.prefix]);

    return (
        <AppFrameworkProvider>
            <Grid container className={classes.container}>
                <Grid item className={classes.leftMenu} xs={2}>
                    <List>
                        {isSuperUser ?
                            <>
                                <ListItem button>
                                    <BarChartOutlinedIcon className={classes.button} />
                                    <Link to='/administrator/statistics'>
                                        <ListItemText className={classes.menuLink}>Statistieken</ListItemText>
                                    </Link>
                                </ListItem>
                                <ListItem button>
                                    <CloudUploadOutlinedIcon className={classes.button} />
                                    <Link to='/administrator/upload'>
                                        <ListItemText className={classes.menuLink}>PDF uploaden</ListItemText>
                                    </Link>
                                </ListItem>
                                <ListItem button>
                                    <FindInPageOutlinedIcon className={classes.button} />
                                    <Link to='/administrator/reindex'>
                                        <ListItemText className={classes.menuLink}>Start indexering</ListItemText>
                                    </Link>
                                </ListItem>
                            </>
                            : null}
                        {isAdmin ?
                            <>
                                <ListItem><Divider /></ListItem>
                                <ListItem button>
                                    <ReplayOutlinedIcon className={classes.button} />
                                    <Link to='/administrator/retryqueue'>
                                        <ListItemText className={classes.menuLink}>Retry queue</ListItemText>
                                    </Link>
                                </ListItem>
                                <ListItem button>
                                    <DeleteForeverOutlinedIcon className={classes.button} />
                                    <Link to='/administrator/delete'>
                                        <ListItemText className={classes.menuLink}>PDF verwijderen</ListItemText>
                                    </Link>
                                </ListItem>
                                <ListItem button>
                                    <SupervisorAccountIcon className={classes.button} />
                                    <Link to='/administrator/users'>
                                        <ListItemText className={classes.menuLink}>Gebruikers</ListItemText>
                                    </Link>
                                </ListItem>
                            </>
                            : null}
                    </List>
                </Grid>

                <Grid item className={classes.content} xs={10}>
                    {payload}
                </Grid>
            </Grid>
        </AppFrameworkProvider >
    );
}
