import React, { FunctionComponent, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { TextField, Grid, makeStyles, Theme, createStyles, Button, Typography } from '@material-ui/core';
import { GetUserContext, AccessRights } from "../contexts/user.context";
import { GetFetchContext, Scope } from '../contexts/fetch.context';
import { appConfig } from '../constants';
import { GetBusyContext } from '../contexts/busy.context';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      marginLeft: theme.spacing(2)
    },
    formitem: {
      margin: theme.spacing(2)
    },
    field: {
      width: '100%',
    }
  })
);

type RemovePdfFormProps = {
}

type Inputs = {
  filename: string;
}

export const RemovePdfForm: FunctionComponent<RemovePdfFormProps> = (props) => {
  const busyContext = GetBusyContext();
  const fetchContext = GetFetchContext();
  const userContext = GetUserContext();
  const classes = useStyles();
  const snackbar = useSnackbar();
  const { register, handleSubmit, errors, reset } = useForm<Inputs>();
  const [message, setMessage] = useState<string>('');

  const [isSuperUser, setIsSuperUser] = useState<boolean>(false);

  useEffect(() => {
    setIsSuperUser(userContext.hasAccessRights(AccessRights.SuperUser));
  }, [userContext, setIsSuperUser]);

  const onSubmit = (data: Inputs) => {
    if (!data?.filename) return;

    const url: string = appConfig.ApiBaseUri + '/delete';
    busyContext.push();
    fetchContext.delete(url, Scope.Admin, {
      filename: data.filename
    }).then((response) => response.text())
      .then(msg => {
        setMessage(msg);
        reset();
      })
      .catch((err: Error) => snackbar.enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => busyContext.pop());
  }

  return <form onSubmit={handleSubmit(onSubmit)} className={classes.form} >
    <Grid container direction="column">
      <Grid item className={classes.formitem}>
        <Typography variant='h1'>
          PDF verwijderen
                </Typography>
        <Typography variant='h6' color='primary'>
          Let op: bestaande bestanden en metadata worden zonder waarschuwing verwijderd
                </Typography>
      </Grid>
      <Grid item className={classes.formitem}>
        <TextField disabled={!isSuperUser} inputRef={register} label="Bestandsnaam" name="filename" error={errors.filename !== undefined} className={classes.field} />
      </Grid>
      <Grid item className={classes.formitem}>
        <Button disabled={!isSuperUser} type="submit" variant="contained" color="primary">Verwijder</Button>
        {message ? <Typography variant='h6' color='primary'>{message}</Typography> : null}
      </Grid>
    </Grid>
  </form>
}